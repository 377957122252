export const englishTranslate = {
    "dashboard": "Dashboard",
    "sensors": "Sensors",
    "users": "Users",
    "customers": "Customers",
    "reports": "Reports",
    "total": "Total",
    "settings": "Settings",
    "all": "All",
    "sensor id": "Sensor ID",
    "customer": "Customer",
    "site": "Site",
    "site details": "Site Details",
    "zone": "Zone",
    "zone details": "Zone Details",
    "close": "Close",
    "scanner": "Scanner",
    "scan": "Scan",
    "sensor type": "Sensor Type",
    "add sensor": "Add Sensor",
    "sensor name": "Sensor Name",
    "zone map": "Zone Map",
    "add user": "Add User",
    "all users": "All Users",
    "superadmin": "Superadmin",
    "regional manager": "Regional Manager",
    "branch manager": "Branch Manager",
    "service technician": "Service Technician",
    "custom users": "Custom Users",
    "username": "Username",
    "email": "Email",
    "first name": "First Name",
    "last name": "Last Name",
    "role": "Role",
    "actions": "Actions",
    "my profile": "My Profile",
    "edit user": "Edit User",
    "update": "Update",
    "please enter your username": "Please Enter Your Username",
    "please enter site name": "Please Enter Site Name",
    "please enter your email": "Please Enter Your Email",
    "please enter valid email": "Please Enter Valid Email",
    "please enter your password": "Please Enter Your Password",
    "please confirm your password": "Please Confirm Your Password",
    "passwords do not match": "Passwords do not match",
    "please enter your first name": "Please Enter Your First Name",
    "please enter your last name": "Please Enter Your Last Name",
    "please select language": "Please Select Language",
    "please enter your timezone": "Please Enter Your Timezone",
    "please enter your phone no.": "Please Enter Your Phone No.",
    "please enter valid phone no.": "Please Enter Valid Phone No.",
    "please enter your city": "Please Enter Your City",
    "please enter your state": "Please Enter Your State",
    "please enter your country": "Please Enter Your Country",
    "please enter your address": "Please Enter Your Address",
    "please enter your zip code/postal code": "Please Enter Your Zip Code/Postal Code",
    "role name": "Role Name",
    "last updated at": "Last Updated At",
    "create customer": "Create Customer",
    "resources": "Resources",
    "sites": "Sites",
    "zones": "Zones",
    "address": "Address",
    "country": "Country",
    "state": "State",
    "city": "City ",
    "zip code/postal code": "Zip Code/Postal Code",
    "contact person": "Contact Person",
    "upload photo": "Upload photo",
    "details": "Details",
    "user details": "User Details",
    "enter user details": "Enter User Details",
    "offline": "Offline",
    "rodent": "Rodent",
    "glue board": "Glue Board",
    "flying insect": "Flying Insect",
    "fly" : "Flying Insect",
    "Crawling Insect": "Crawling Insect",
    "crawling insect": "Crawling Insect",
    "add site": "Add Site",
    "site name": "Site Name",
    "select site type": "Select Site Type",
    "air quality": "Air Quality",
    "zone list": "Zone List",
    "zone name": "Zone Name",
    "select site": "Select Site",
    "edit profile": "Edit Profile",
    "edit theme": "Edit Theme",
    "change password": "Change Password",
    "new password": "New Password",
    "confirm new password": "Confirm New Password",
    "sign out": "Sign Out",
    "sign in": "Sign In",
    "enter username": "Enter Username",
    "enter password": "Enter Password",
    "password": "Password",
    "remember me": "Remember Me",
    "forgot password?": "Forgot Password?",
    "download csv file": "Download CSV File",
    "download pdf file": "Download PDF File",
    "download png file": "Download PNG File",
    "pest infestation": "Pest Infestation",
    "notification": "Notification",
    "timeseries": "Timeseries",
    "add action": "Add Action",
    "online": "Online",
    "select customer": "Select Customer",
    "select zone": "Select Zone",
    "please choose a excel file": "Please choose a Excel File",
    "ok": "Ok",
    "clear": "Clear",
    "delete": "Delete",
    "re-assign sensor": "Re-Assign Sensor",
    "edit sensor": "Edit Sensor",
    "update sensor": "Update Sensor",
    "sensor details": "Sensor Details",
    "sensor unavailable": "Sensor Unavailable",
    "sensor id unavailable": "Sensor Id Unavailable",
    "sensor installed": "Sensor Installed",
    "re assign sensor": "Re Assign Sensor",
    "re assigned successfully": "Re Assigned Successfully",
    "directional chart": "Directional Chart",
    "no sensor available": "No Sensor Available",
    "to add a sensor.": "To Add a Sensor.",
    "select pest type": "Select Pest Type",
    "customer-site time series comparison": "Customer-Site time series comparison",
    "hour": "Hour",
    "week": "Week",
    "month": "Month",
    "year": "Year",
    "notifications": "Notifications",
    "select sensor": "Select Sensor",
    "enable": "Enable",
    "disable": "Disable",
    "user successfully created.": "User Successfully Created.",
    "user successfully updated.": "User Successfully Updated.",
    "customer successfully created.": "Customer Successfully Created.",
    "show customer list": "Show Customer List",
    "individual customer": "Individual Customer",
    "site successfully created.": "Site Successfully Created.",
    "show site list": "Show Site List",
    "show zone list": "Show Zone List",
    "choose  a activity category": "Choose  A Activity Category",
    "processing steps": "Processing Steps",
    "assigned successfully": "Assigned Successfully",
    "yes": "YES",
    "no": "NO",
    "updated successfully": "Updated Successfully",
    "please choose a image file": "Please choose a Image File",
    "no user available": "No User Available",
    "click here": "Click Here",
    "Clone Role": "Clone Role",
    "edit customer": "Edit Customer",
    "edit site": "Edit Site",
    "day": "Day",
    "today": "Today",
    "deleted successfully": "Deleted Successfully",
    "created successfully": "Created Successfully",
    "edited successfully": "Edited Successfully",
    "password changed successfully.": "Password Changed Successfully.",
    "site successfully updated.": "Site Successfully Updated.",
    "customer successfully updated.": "Customer Successfully Updated.",
    "select all": "Select All",
    "collapse": "Collapse",
    "expand": "Expand",
    "select language": "Select Language",
    "direction": "Direction",
    "sensor": "Sensor",
    "hour of the day": "Hour of the Day",
    "select timezone": "Select Timezone",
    "roles": "Roles",
    "total count": "Total Count",
    "from": "From",
    "to": "to",
    "no.": "No.",
    "stop scan": "Stop Scan",
    "aqi": "AQI",
    "at": "at",
    "edit role": "Edit Role",
    "updated by": "Updated By",
    "updated at": "Updated At",
    "created by": "Created By",
    "successfully sensor installed": "Successfully Sensor Installed",
    "sensor updated successfully": "Sensor updated successfully",
    "custom role created successfully": "Custom Role created successfully",
    "custom role edited successfully": "Custom Role edited successfully",
    "Sensor already scanned": "Sensor already scanned",
    "file formats should be JPG/JPEG/PNG": "File formats should be JPG/JPEG/PNG",
    "sensor(s) in the list were added successfully": "Sensor(s) in the list were added successfully",
    "enter your pincode": "Enter Your Pincode",
    "enter your country": "Enter Your Country",
    "enter your state": "Enter Your State",
    "enter your city": "Enter Your City",
    "enter your street": "Enter your street",
    "please select your timezone": "Please Select Your Timezone",
    "please enter zone name": "Please Enter Zone Name",
    "please select role": "Please select Role",
    "please enter your customer name": "Please Enter Your Customer Name",
    "please enter your contact person": "Please Enter Your Contact Person",
    "pest type": "Pest Type",
    "last updated": "Last Updated",
    "please enter your current password": "Please enter your current password",
    "Passwords must be between 6 and 12 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols.": "Passwords must be between 6 and 12 characters and contain at least two of the following: uppercase letters, lowercase letters, numbers, and symbols.",
    "please select customer (optional)": "Please Select Customer (optional)",
    "sensor error": "Sensor Error",
    "please choose customer": "Please Choose Customer",
    "please choose site": "Please Choose Site",
    "please choose zone": "Please Choose Zone",
    "please enter sensor name": "Please Enter Sensor Name",
    "please choose sensor type": "Please Choose Sensor Type",
    "no user avilable.": "No User Avilable.",
    "rodent sensor": "Rodent Sensor",
    "please enter sensor id": "Please Enter Sensor Id",
    "sensor already installed": "Sensor Already Installed",
    "deployment type": "Deployment Type",
    "max length 200": "Max Length 200",
    "max length 20": "Max Length 20",
    "sensor name must be a minimum of 6 characters": "Sensor name must be minimum of 6 characters",
    "sensor name cannot be blank": "Sensor name cannot be blank",
    "max length 8": "Max Length 8",
    "cancel": "Cancel",
    "qr code scan": "QR Code Scan",
    "file size should be less than 5 MB": "File size should be less than 5 MB",
    "successfully uninstalled the sensor": "Successfully uninstalled the sensor",
    "successfully reassigned to selected customer": "Successfully Reassigned to selected customer",
    "complete installation": "Complete Installation",
    "This Sensor is not currently active on the IoP system and cannot be used.": "This Sensor is not currently active on the IoP system and cannot be used.",
    "Invalid sensor ID! This sensor is already assigned elsewhere.": "Invalid sensor ID! This sensor is already assigned elsewhere.",
    "This sensor is already installed on your account.": "This Sensor is already installed on your account.",
    "This sensor is already added to your account.": "This sensor is already added to your account.",
    "This sensor is has already been installed on the IoP system.": "This Sensor is has already been installed on the IoP system.",
    "Invalid sensor ID specified! Please enter a valid sensor ID for installation.": "Invalid sensor ID! Please enter a valid sensor ID for installation.",
    "error during get sensor metadata details : sensorId not available": "Error during get sensor metadata details : sensorId not available",
    "no whitespace at the beginning and end": "No whitespace at the beginning and end",
    "date & time": "Date & Time",
    "manage roles": "Manage Roles",
    "reset": "Reset",
    "choose a reporting manager": "Choose a Reporting Manager",
    "organisation": "Organisation",
    "create new custom role": "Create New Custom Role",
    "edit custom role": "Edit Custom Role",
    "parent role": "Parent Role",
    "enter new custom role name": "Enter New Custom Role Name",
    "please enter custom role name": "Please Enter Custom Role Name",
    "please enter different custom role name": "Please Enter Different Custom Role Name",
    "enter the role description": "Enter the Role Description",
    "no role available": "No Role Available",
    "permission": "Permission",
    "create": "Create",
    "view": "View",
    "please enter a valid mobile number": "Please Enter a Valid Mobile Number",
    "clone": "Clone",
    "description": "Description",
    "delete role": "Delete Role",
    "max length 225": "Max Length 225",
    "re-enter password": "Re-enter Password",
    "please enter re-enter password": "Please Enter Re-enter Password",
    "name": "Name",
    "reporting manager": "Reporting Manager",
    "update user": "Update User",
    "timezone": "Timezone",
    "click to enable": "Click to Enable",
    "click to disable": "Click to Disable",
    "account overview": "Account Overview",
    "reporting users": "Reporting Users",
    "detections": "Detections",
    "sensors overview": "Sensors Overview",
    "view all": "View all",
    "pest detections": "Pest Detections",
    "all sensors": "All Sensors",
    "new": "New",
    "active": "Active",
    "idle": "Idle",
    "home": "Home",
    "glue board details": "Glue Board Details",
    "added by": "Added by",
    "added on": "Added on",
    "location description": "Location Description",
    "sensor pictures": "Sensor Pictures",
    "glue board id": "Glue Board ID",
    "24 hrs": "24 Hrs",
    "12 hrs": "12 Hrs",
    "6 hrs": "6 Hrs",
    "1 hr": "1 Hr",
    "1 week": "1 Week",
    "1 month": "1 Month",
    "lifetime": "Lifetime",
    "remove sensor": "Remove Sensor",
    "What is the reason for removing this sensor?": "What is the reason for removing this sensor?",
    "sensor damaged": "Sensor damaged",
    "other reason": "Other reason",
    "Yes, I confirm this sensor to be permanently removed": "Yes, I confirm permanent removal of this sensor",
    "Enter Sensor ID or Scan QR code": "Enter Sensor ID or Scan QR code",
    "Enter Sensor name": "Enter Sensor name",
    "select deployment type": "Select Deployment Type",
    "enter sensor location description": "Enter Sensor Location Description",
    "sensor location description": "Sensor Location Description",
    "Describe the placement of the sensor in words.": "Describe the placement of the sensor in words.",
    "add sensors": "Add Sensors",
    "basic details": "Basic Details",
    "select sensors": "Select Sensors",
    "review & complete": "Review & Complete",
    "number of sensors to be added": "Number of sensors to be added",
    "please choose number of sensors": "Please choose number of sensors",
    "back": "Back",
    "save & continue": "Save & Continue",
    "I confirm above sensors to be added to my account.": "I confirm the addition of the above sensors to my account.",
    "max length 255": "Max length 255",
    "please choose pest type": "Please Choose Pest Type",
    "last seen": "Last Seen",
    "Unavailable": "Unavailable",
    "comm status": "Comm Status", 
    "Comm Status": "Comm Status",
    "sensor images": "Sensor Images",
    "proceed": "Proceed",
    "are you sure want to cancel it ?": "Are you sure want to cancel it ?",
    "weeks": "Weeks",
    "days": "Days",
    "hours": "Hours",
    "customer information": "Customer Information",
    "Enter Customer Name": "Enter Customer Name",
    "contact person details": "Contact Person Details",
    "I confirm to add this new customer to my account": "I confirm the addition of this new customer to my account",
    "Update Avatar": "Update Avatar",
    "Enter Email ID": "Enter Email ID",
    "enter phone number": "Enter Phone Number",
    "enter contact person name": "Enter Contact Person Name",
    "all customers": "All Customers",
    "all sites": "All Sites",
    "all zones": "All Zones",
    "customer, site, zone": "Customer, Site, Zone",
    "manage sites": "Manage Sites",
    "postal code": "Postal Code",
    "phone": "Phone",
    "enter address": "Enter Address",
    "phone number": "Phone Number",
    "email id": "Email ID",
    "customer address": "Customer Address",
    "Manage Sites and Zones": "Manage Sites and Zones",
    "contact person name": "Contact Person Name",
    "I confirm to add this new customer to my account, and to all the reporting managers above me.": "I confirm to add this new customer to my account, and to all the reporting managers above me.",
    "This role name is already taken. Please try a different one!": "This role name is already taken. Please try a different one!",
    "I confirm to add the above sensors to my account, and to all the managers of this site.": "I confirm to add the above sensors to my account, and to all the managers of this site.",
    "please select the checkbox": "Please select the checkbox",
    "remove customer": "Remove Customer",
    "customer removed successfully": "Customer Removed Successfully",
    "please enter a valid sensor id": "Please Enter a Valid Sensor ID",
    "This site name is already taken. Please try a different one!": "This site name is already taken. Please try a different one!",
    "This zone name is already taken. Please try a different one!": "This zone name is already taken. Please try a different one!",
    "This sensor name is already taken. Please try a different one!": "This sensor name is already taken. Please try a different one!",
    "sensor name already added": "Sensor Name Already Added",
    "24 Hours": "24 Hours",
    "This customer name is already taken. Please try a different one!": "This customer name is already taken. Please try a different one!",
    "resolved": "Resolved",
    "resolve": "Resolve",
    "Recent Detection": "Recent Detection",
    "detection log": "Detection Log",
    "time stamp": "Time Stamp",
    "detection details": "Detection Details",
    "new detection": "New Detection",
    "new detections": "New Detections",
    "no detections": "No Detections",
    "Proof of Detection Resolution": "Proof of Detection Resolution",
    "I have completed the pest management and sensor services required to resolve this detection.": "I have completed the pest management and sensor services required to resolve this detection.",
    "Did you find any evidence of pest presence?": "Did you find any evidence of pest presence?",
    "Successfully resolved activity": "Successfully resolved activity",
    "Resolution Information": "Resolution Information",
    "Resolution Number": "Resolution Number",
    "resolved by": "Resolved By",
    "resolved on": "Resolved On",
    "Date": "Date",
    "Time": "Time",
    "view location": "View Location",
    "Detection has been successfully resolved.": "Detection has been successfully resolved.",
    "sensor is malfunctioning": "Sensor is malfunctioning",
    "sensor is no longer required": "Sensor is no longer required",
    "This sensor will be marked as malfunctioning and removed permanently. The removed sensor cannot be reused.": "This sensor will be marked as malfunctioning and removed permanently. The removed sensor cannot be reused.",
    "This sensor will be removed and can be later added back as required.": "This sensor will be removed and can be later added back as required.",
    "I confirm the permanent removal of this sensor.": "I confirm the permanent removal of this sensor.",
    "I confirm the removal of this sensor.": "I confirm the removal of this sensor.",
    "The sensor has been permanently removed": "The sensor has been permanently removed",
    "The sensor has been successfully removed": "The sensor has been successfully removed",
    "date range": "Date Range",
    "no Glue board details": "No Glue Board Details",
    "replace sensor": "Replace Sensor",
    "basic information": "Basic Information",
    "sensor replacement": "Sensor Replacement",
    "Glue board detection breach limit": "Glue board detection breach limit",
    "Glue board duration breach limit": "Glue board duration breach limit",
    "Communication status": "Communication Status",
    "pictures": "Pictures",
    "What is the reason for replacing this sensor?": "What is the reason for replacing this sensor?",
    "Sensor malfunctioning": "Sensor Malfunctioning",
    "Sensor Damaged": "Sensor Damaged",
    "Sensor Lost": "Sensor Lost",
    "Technician can give the statement of the existing sensor condition.": "Technician can give the statement of the existing sensor condition.",
    "Please describe below": "Please Describe Below",
    "confirm & replace": "Confirm & Replace",
    "enter the new sensor details": "Enter the new sensor details",
    "I confirm the addition of the new sensor in place of the sensor selected for replacement. This action cannot be undone.": "I confirm the addition of the new sensor in place of the sensor selected for replacement. This action cannot be undone.",
    "The sensor has been replaced successfully!": "The sensor has been replaced successfully!",
    "View Sensor list": "View Sensor list",
    "breach limits": "Breach limits",
    "Default No. of days for the glueboard expiry": "Default No. of days for the glue board expiry",
    "Default max fly detections allowed on the glueboard": "Default Max flying insect detections allowed on the glue board",
    "please enter No. of days for the glueboard expiry": "Please enter No. of days for the glue board expiry",
    "please enter max flying insect detections allowed on the glue board": "Please enter max flying insect detections allowed on the glue board",
    "Default fly detections limit": "Default Flying Insect detections limit",
    "Default Rodent detections limit": "Default Rodent detections limit",
    "Please Enter Flying Insect Critical Control Limit": "Please Enter Flying Insect Critical Control Limit",
    "Please Enter Crawling Insect Critical Control Limit": "Please Enter Crawling Insect Critical Control Limit",
    "Please Enter Rodent Critical Control Limit": "Please Enter Rodent Critical Control Limit",
    "language": "Language",
    "No. of days for the glueboard expiry": "No. of days remaining before glue board expiry",
    "max fly detections allowed on the glueboard": "Max Flying Insect detections allowed on the glue board",
    "Default No. of days for the glue board expiry between 1 to 90": "Default No. of days for the glue board expiry between 1 to 90",
    "Default max flying insect detections allowed on the glue board between 1 to 1999": "Default Max Flying Insect detections allowed on the glue board between 1 to 1999",
    "Default flying insect detections limit between 1 to 1999": "Default Flying Insect detections limit between 1 to 1999",
    "Default Crawling Insect detections limit between 1 to 1999": "Default Crawling Insect detections limit between 1 to 1999",
    "Default Rodent detections limit between 1 to 1999": "Default Rodent detections limit between 1 to 1999",
    "This sensor will be marked as malfunctioning and removed permanently. The replaced sensor cannot be reused.": "This sensor will be marked as malfunctioning and removed permanently. The replaced sensor cannot be reused.",
    "max fly detections allowed on the glueboard between 1 to 1999": "Max Flying insect detections allowed on the glue board between 1 to 1999",
    "No. of days for the glue board expiry between 1 to 90": "No. of days for the glue board expiry between 1 to 90",
    "new sensors": "New Sensors",
    "active sensors": "Active Sensors",
    "idle sensors": "Idle Sensors",
    "offline sensors": "Offline Sensors",
    "breach": "Breach",
    "to create a customer": "to Create a Customer",
    "Replacing sensor should be of the same pest type.": "Replacing sensor should be of the same pest type.",
    "site type": "Site Type",
    "no customer available.": "No customer available.",
    "no site available": "No site available",
    "site event log": "Site event log",
    "add site & zone": "Add Site & Zone",
    "add more": "Add More",
    "enter state": "Enter State",
    "Enter zip code/postal code": "Enter zip code/postal code",
    "enter site name": "Enter Site Name",
    "enter city": "Enter City",
    "enter country": "Enter Country",
    "service team": "Service Team",
    "user name": "User Name",
    "I confirm the addition of this new site to my account, service team and all the reporting managers.": "I confirm the addition of this new site to my account, service team and all the reporting managers.",
    "please select timezone": "Please Select Timezone",
    "site information": "Site Information",
    "edit site & zone": "Edit Site & Zone",
    "Flying Insect Glue Board Settings": "Flying Insect Glue Board Settings",
    "Critical Control Limits": "Critical Control Limits",
    "Default Flying Insect Critical Control Limit of the Zone": "Default Flying Insect Critical Control Limit of the Zone",
    "Default Crawling Insect Critical Control Limit of the Zone": "Default Crawling Insect Critical Control Limit of the Zone",
    "Default Rodent Critical Control Limit of the Zone": "Default Rodent Critical Control Limit of the Zone",
    "resolved detections": "Resolved Detections",
    "is Breached on": "is Breached on",
    "is Breached at": "is Breached at",
    "Flying Insect Glue Board Detections": "Flying Insect Glue Board Detections",
    "Zone Rodent Critical Control Limit": "Zone Rodent Critical Control Limit",
    "Zone Fly Critical Control Limit": "Zone Flying Insect Critical Control Limit",
    "Zone Crawling Insect Critical Control Limit": "Zone Crawling Insect Critical Control Limit",
    "manage zones": "Manage Zones",
    "breach limit": "Breach Limit",
    "click": "Click",
    "This service team member is already taken. Please try a different one!": "This service team member is already assigned. Please select another member!",
    "add team member": "Add Team Member",
    "no service team available": "No Service Team Available",
    "to add team member": "To Add Team Member",
    "revoke access": "Revoke Access",
    "updated on": "Updated on",
    "add member": "Add Member",
    "number of sites assigned": "Number of sites assigned",
    "select user": "Select User",
    "please select user": "Please select user",
    "zone detection resolution": "Zone Detection Resolution",
    "action": "Action",
    "site detection resolution": "Site Detection Resolution",
    "I have completed the pest management and sensor services required to resolve the new detections in this site.": "I have completed the pest management and sensor services required to resolve the new detections in this site.",
    "Sensor Detection Resolution": "Sensor Detection Resolution",
    "Grant Access": "Grant Access",
    "Add team member to the site": "Add team member to the site",
    "I confirm granting site access to this member and to all the reporting managers.": "I confirm granting site access to this member and to all the reporting managers.",
    "has been granted access to the selected site": "has been granted access to the selected site",
    "Remove team member from the site": "Remove team member from the site",
    "I confirm revoking site access from this member.": "I confirm revoking site access from this member.",
    "has been revoked access to the selected site": "has been revoked access to the selected site",
    "detected": "Detected",
    "notifications center": "Notifications Center",
    "Edit Service Team Members for the site by changing users below": "Edit Service Team Members for the site by changing users below",
    "Add Service Team Members for the site by choosing users below": "Add Service Team Members for the site by choosing users below",
    "Please use a different username": "Please use different username",
    "is": "is",
    "edit": "Edit",
    "replace": "Replace",
    "remove": "Remove",
    "Zone Information": "Zone Information",
    "User": "User",
    "important notifications": "Important Notifications",
    "confirmation": "Confirmation",
    "Are you sure you want to mark all the new notifications as read?": "Are you sure you want to mark all the new notifications as read?",
    "Yes, Proceed": "Yes, Proceed",
    "The new notifications have been successfully cleared. They can still be accessed from the Notifications Center.": "The new notifications have been successfully cleared. They can still be accessed from the Notifications Center.",
    "Site Detections Resolved by": "Site Detections Resolved by",
    "Zone Detections Resolved by": "Zone Detections Resolved by",
    "New Flying Insect Detections at Site": "New Flying Insect Detections at Site",
    "New Crawling Insect Detections at Site": "New Crawling Insect Detections at Site",
    "New Rodent Detections at Site": "New Rodent Detections at Site",
    "Flying Insect Sensors": "Flying Insect Sensors",
    "Rodent Sensors": "Rodent Sensors",
    "zone detections resolved": "Zone Detections Resolved",
    "site detections resolved": "Site Detections Resolved",
    "by": "by",
    "is Breached!": "is Breached!",
    "Breached!": "Breached!",
    "All Notifications": "All Notifications",
    "Breach Alerts": "Breach Alerts",
    "Site Events": "Site Events",
    "Sensor Events": "Sensor Events",
    "Mark all as read": "Mark all as read",
    "I have completed the pest management and sensor services required to resolve the new detections in this zone.": "I have completed the pest management and sensor services required to resolve the new detections in this zone.",
    "total sensors": "Total Sensors",
    "IoP® Dashboard": "IoP® Dashboard",
    "Flying Insect Detections": "Flying Insect Detections",
    "Rodent Detections": "Rodent Detections",
    "Rodent Detection": "Rodent Detection",
    "Fly Detection": "Flying Insect Detection",
    "Fly Trend": "Flying Insect Trend",
    "Rodent Trend": "Rodent Trend",
    "New Detections by Pest Type": "New Detections by Pest Type",
    "Detections - New vs Resolved": "Detections - New vs Resolved",
    "View All New Detections": "View All New Detections",
    "Sensors grouped by Pest Type": "Sensors grouped by Pest Type",
    "View All Sensors": "View All Sensors",
    "Sensors grouped by Communication Status": "Sensors grouped by Communication Status",
    "© 2024 Evoscien. All Rights Reserved.": "© 2024 Evoscien. All Rights Reserved.",
    "There are": "There are",
    "more new notifications,": "more new notifications,",
    "detections overview": "Detections Overview",
    "Fly Sensor Detections Resolved by": "Flying Insect Sensor Detections Resolved by",
    "Fly Sensor Detections Resolved": "Flying Insect Sensor Detections Resolved",
    "New Fly Detections": "New Flying Insect Detections",
    "New Rodent Detections": "New Rodent Detections",
    "create site": "Create Site",
    "to create a site.": "to create a site.",
    "Add Zone Map": "Add Zone Map",
    "please enter the role description": "Please Enter the Role Description",
    "Sensor Detections Resolved by": "Sensor Detections Resolved by",
    "sensor detections resolved": "Sensor Detections Resolved",
    "current password": "Current Password",
    "reset password": "Reset Password",
    "Additional Comments": "Additional Comments",
    "Do you want to resolve the detections on this site?": "Do you want to resolve the detections on this site?",
    "Do you want to resolve the detections on this sensor?": "Do you want to resolve the detections on this sensor?",
    "Do you want to resolve the detections on this zone?": "Do you want to resolve the detections on this zone?",
    "Resolve": "Resolve",
    "Cancel": "Cancel",
    "pest infestation summary": "Pest Infestation Summary",
    "time series comparison": "Time Series Comparison",
    "Time Series": "Time Series",
    "Cumulative Sum": "Cumulative Sum",
    "Hours Distribution": "Hours Distribution",
    "Percentage": "Percentage",
    "Trend is unavailable for the current selection criteria.": "Trend is unavailable for the current selection criteria.",
    "Data Aggregation By": "Data Aggregation By",
    "Detections Report": "Detections Report",
    "- Create Customer -": "- Create Customer -",
    "Critical Control Limit": "Critical Control Limit",
    "max length 12": "Max Length 12",
    "create user": "Create User",
    "to create a user": "to Create a User",
    "pest detections report": "Pest Detections Report",
    "all crawling insect": "All Crawling Insect",
    "all rodents": "All Rodents",
    "all crawling insect sensors": "All Crawling Insect Sensors",
    "all rodent sensors": "All Rodent Sensors",
    "all fly sensors": "All Flying Insect sensors",
    "Crawling Insect Critical Control Limit": "Crawling Insect Critical Control Limit",
    "Fly Critical Control Limit": "Flying Insect Critical Control Limit",
    "Rodent Critical Control Limit": "Rodent Critical Control Limit",
    "max length 24": "Max Length 24",
    "min length 6": "Min Length 6",
    "year I": "Year I",
    "year II": "Year II",
    "week I": "Week I",
    "week II": "Week II",
    "month I": "Month I",
    "month II": "Month II",
    "day I": "Day I",
    "day II": "Day II",
    "comparison type": "Comparison Type",
    "Max Custom Date Range selection is 90 days at a stretch": "Max Custom Date Range selection is 90 days at a stretch",
    "Crawling Insect Cumulative Sum": "Crawling Insect Cumulative Sum",
    "Rodent Cumulative Sum": "Rodent Cumulative Sum",
    "Flying Insect Cumulative Sum": "Flying Insect Cumulative Sum",
    "glue board expiry": "Glue Board Expiry",
    "Wi-Fi": "Wi-Fi",
    "max allowed on glue board": "max allowed on glue board",
    "please select upto current month": "Please Select upto Current Month",
    "events log": "Events Log",
    "event": "Event",
    "month of the year": "Month of the Year",
    "day of the month": "Day of the Month",
    "day of the week": "Day of the Week",
    "Sensor Management": "Sensor Management",
    "LPWAN": "LPWAN",
    "all detections": "All Detections",
    "Glue Trap": "Glue Trap",
    "Standalone": "Standalone",
    "Bait Station": "Bait Station",
    "max": "Max",
    "years": "Years",
    "hr": "hr",
    "hrs": "hrs",
    "min": "min",
    "mins": "mins",
    "sec": "sec",
    "secs": "secs",
    "ago": "ago",
    "days left": "days left",
    "day left": "day left",
    "Expired": "Expired",
    "days ago": "days ago",
    "day ago": "day ago",
    "Entry from Left Side": "Entry from Left Side",
    "Entry from Right side": "Entry from Right side",
    "Values denote the rodent's direction of entry on the sensor": "Values denote the rodent's direction of entry on the sensor",
    "Values denote the side of entry of crawling insect on the sensor": "Values denote the side of entry of crawling insect on the sensor",
    "Due to variations in size, the side of entry for": "Due to variations in size, the side of entry for",
    "some rodent detections remains unidentified.": "some rodent detections remains unidentified.",
    "events": "Events",
    "added": "Added",
    "replaced": "Replaced",
    "removed": "Removed",
    "sensor idle": "Sensor Idle",
    "sensor active": "Sensor Active",
    "sensor offline": "Sensor Offline",
    "zone critical control limit breached": "Zone Critical Control Limit Breached",
    "flying insect max detections breached": "Flying Insect Max Detections Breached",
    "flying insect glue board expiry breached": "Flying Insect Glue Board Expiry Breached",
    "sensor established connection": "Sensor Established Connection",
    "wifi configuration successful": "WiFi Configuration Successful",
    "flying insect glue board replaced": "Flying Insect Glue Board Replaced",
    "sensor name edited": "Sensor Name Edited",
    "sensor pictures edited": "Sensor Pictures Edited",
    "location description edited": "Location Description Edited",
    "Detections between": "Detections between",
    "in": "in",
    "Hrs": "Hrs",
    "on": "on",
    "during": "during",
    "between": "between",
    "hrs on": "hrs on",
    "detection": "Detection",
    "Day vs Day": "Day vs Day",
    "Week vs Week": "Week vs Week",
    "Month vs Month": "Month vs Month",
    "Year vs Year": "Year vs Year",
    "Educational Institution": "Educational Institution",
    "Food Processing": "Food Processing",
    "Home": "Home",
    "Hospital": "Hospital",
    "Hotel": "Hotel",
    "Loading Bay Warehouse": "Loading Bay Warehouse",
    "Office": "Office",
    "Restaurant": "Restaurant",
    "Retail": "Retail",
    "Theatre": "Theatre",
    "Airport": "Airport",
    "Bar": "Bar",
    "Cafe": "Cafe",
    "Cruise line": "Cruise Liner",
    "Supermarket": "Supermarket",
    "Train Station": "Train Station",
    "glueboard expiry": "Glue Board Expiry",
    "glueboard max detections": "Glue Board Max Detections",
    "zone ccl breached": "Zone CCL Breached",
    "established connection": "Established connection",
    "established connection wifi": "Established connection Wi-Fi",
    "wifi config successful": "Wi-Fi Config Successful",
    "glueboard changed": "Glue Board Changed",
    "detections resolved": "Detections Resolved",
    "Web App": "Web App",
    "Mobile App": "Mobile App",
    "sensor add": "Sensor Add",
    "sensor edit": "Sensor Edit",
    "sensor remove": "Sensor Remove",
    "sensor replace": "Sensor Replace",
    "Site deleted successfully.": "Site Deleted Successfully.",
    "Zone deleted successfully.": "Zone Deleted Successfully.",
    "Super Admin": "Super Admin",
    "Regional Manager": "Regional Manager",
    "Branch Manager": "Branch Manager",
    "Customer Service Executive": "Customer Service Executive",
    "Service Manager": "Service Manager",
    "Service Technician": "Service Technician",
    "Customer Representative": "Customer Representative",
    "Super Admin is the root level access of the organisation.": "Super Admin is the root level access of the organisation.",
    "Regional Manager is the managerial role with scope of multiple branches. Multiple Branch Managers can report under Regional Manager.": "Regional Manager is a managerial role covering multiple branches. Branch Managers report to the Regional Manager.",
    "Branch Manager is the managerial role for one branch. Branch manager is empowered with all functions within scope of a branch.": "Branch Manager is the managerial role for one branch, empowered with all the functions within the scope of a branch.",
    "Customer Service Executive is the custodian of achieving customer satisfaction within a branch. One or more Customer Service Executives can manage the customers in a branch as required.": "Customer Service Executives are responsible for ensuring customer satisfaction within a branch. One or more Customer Service Executives can manage customers in a branch as required.",
    "In large branches, Service Managers manage the routing and productivity of the Service Technicians on the field. One or more Service Managers, manage technicians under them for best utilisation of their time.": "Service Managers manage the routing and productivity of Service Technicians in the field. One or more Service Managers manage the technicians under them.",
    "Service Technicians (a.k.a PMP, Pest Management Personnel) are the field agents who visit the customers site to deliver the Sensor Installation, Pest Control, Sensor Malfunction and Periodic maintenance services.": "Service Technicians are the field agents who visit the customers' sites to deliver sensor Installation, resolve pest activity and conduct periodic maintenance.",
    "Customer Representatives are the site/facility managers of the customer for which the services are provided. A Customer Representative can be mapped to only one customer": "Customer Representatives are the customer's site/facility managers.",
    "site events log": "Site Events Log",
    "Site Management": "Site Management",
    "Zone Management": "Zone Management",
    "This sensor ID does not exist! Kindly recheck and try again.": "This sensor ID does not exist! Kindly recheck and try again.",
    "Invalid Sensor! This sensor does not belong to your organisation.": "This sensor does not belong to your organisation.",
    "Sensor is validated successfully!": "Sensor is successfully validated!",
    "Invalid sensor! This sensor is already added elsewhere.": "This sensor has already been added elsewhere.",
    "Invalid sensor! This sensor has been removed as malfunctioning and can no longer be added.": "This sensor has been marked as malfunctioning and can no longer be added.",
    "Invalid sensor! This sensor has been replaced as malfunctioning / damaged / lost and can no longer be added.": "This sensor has been marked as malfunctioning/damaged/lost and can no longer be added.",
    "Invalid sensor! This sensor was added to site": "Invalid sensor! This sensor was added to site",
    " zone": " zone",
    "and is currently in use.": "and is currently in use.",
    "site created": "Site Created",
    "site edited": "Site Edited",
    "service team edited": "Service Team Edited",
    "zone added": "Zone Added",
    "zone name edited": "Zone Name Edited",
    "zone deleted": "Zone Deleted",
    "zone ccl changed rodent": "Zone ccl Changed - Rodent",
    "zone ccl changed fly": "zone ccl Changed - Flying Insect",
    "Zone Rodent critical control limit changed from": "Zone Rodent Critical Control Limit changed from",
    "Zone Crawling Insect Critical Control Limit changed from": "Zone Crawling Insect Critical Control Limit changed from",
    "Zone Fly critical control limit changed from": "Zone Flying Insect critical control limit changed from",
    " to ": " to ",
    "sensor added": "Sensor Added",
    "delete site": "Delete Site",
    "delete zone": "Delete Zone",
    "zone flying insect critical control limit breached": "Zone Flying Insect Critical Control Limit Breached",
    "grouped crawling insect detections by site": "Grouped Crawling Insect Detections by Site",
    "grouped rodent detections by site": "Grouped Rodent Detections by Site",
    "grouped fly detections by site": "Grouped Flying Insect Detections by Site",
    "type": "Type",
    "site contact person name": "Site Contact Person Name",
    "None Selected": "None Selected",
    "All Selected": "All Selected",
    "Multiple Selected": "Multiple Selected",
    "glueboard max fly detections": "Glue Board Max Flying Insect Detections",
    "edited": "Edited",
    "sensor photo": "Sensor Pictures",
    "add sensor pictures": "Add Sensor Pictures",
    "sensor picture(s)": "Sensor Picture(s)",
    "sensor picture": "Sensor Picture",
    "flying insect glueboard max detections": "Flying Insect Glue Board Max Detections",
    "flying insect glueboard expiry": "Flying Insect Glue Board Expiry",
    "Sensor WiFi Configuration Successful": "Sensor WiFi Configuration Successful",
    "zone crawling insect breached": "Zone Crawling Insect Breached",
    "zone fly breached": "Zone Flying Insect Breached",
    "zone rodent breached": "Zone Rodent Breached",
    "flying insect glueboard max detections breached": "Flying Insect Glue Board Max Detections Breached",
    "sensor edited": "Sensor Edited",
    "sensor removed": "Sensor Removed",
    "sensor replaced": "Sensor Replaced",
    "edit service team": "Edit Service Team",
    "sensor established connection via wifi": "Sensor Established Connection via Wi-Fi",
    "sensor wifi configuration successful": "Sensor Wi-Fi Configuration Successful",
    "zone rodent critical control limit edited": "Zone Rodent Critical Control Limit Edited",
    "zone flying insect critical control limit edited": "Zone Flying Insect Critical Control Limit Edited",
    "zone crawling insect critical control limit edited": "Zone Crawling Insect Critical Control Limit Edited",
    "zone crawling insect critical control limit breached": "Zone Crawling Insect Critical Control Limit Breached",
    "flying insect glueboard expiry changed": "Flying Insect Glue Board Expiry Changed",
    "flying insect glueboard max detections changed": "Flying Insect Glue Board Max Detections Changed",
    "Detections Resolution": "Detections Resolution",
    "Flying Insect Glueboard Expiry Changed from": "Flying Insect Glue Board Expiry Changed from",
    "days to": "days to",
    "Days": "days",
    "Flying Insect Glueboard Max Detections Changed from": "Flying Insect Glue Board Max Detections Changed from",
    "site information edited": "Site Information Edited",
    "contact person details edited": "Contact Person Details Edited",
    "zone edited": "Zone Edited",
    "Changed from": "Changed from",
    "contact phone number": "Contact Phone Number",
    "contact email id": "Contact Email ID",
    "zone map edited": "Zone Map Edited",
    "Zone Name, Map Edited": "Zone Name, Map Edited",
    "New Password and Confirm New Password does not match": "New Password and Confirm New Password does not match",
    "You have entered invalid old password.": "You have entered an invalid/old password.",
    "profile": "Profile",
    "preferences": "Preferences",
    "New Password shoudn't be same as old password.": "The new Password cannot be the same as the old password.",
    "Flying Insect Glueboard": "Flying Insect Glue Board",
    "user roles": "User Roles",
    "The side of the entry for": "The side of the entry for",
    "rodent detections is unknown due to variations in the size of the rodent.": "rodent detections is unknown due to variations in the size of the rodent.",
    "Critically low, requires replacement!": "Critically low, requires replacement!",
    "total sites": "Total Sites",
    "100% fully charged": "100% Fully Charged",
    "remaining": "Remaining",
    "administration": "Administration",
    "created by (username)": "Created By (Username)",
    "reporting manager (username)": "Reporting Manager (Username)",
    "Tobacco Beetle Glueboard": "Tobacco Beetle Glue Board",
    "Default max cigarette beetle detections allowed on the glueboard": "Default max tobacco beetle detections allowed on the glue board",
    "please enter max cigarette beetle detections allowed on the glueboard": "Please enter max tobacco beetle detections allowed on the glue board",
    "Default max cigarette beetle detections allowed on the glueboard between 1 to 1999": "Default max tobacco beetle detections allowed on the glue board (1 to 1999)",
    "Default Tobacco Beetle Critical Control Limit of the Zone": "Default Tobacco Beetle Critical Control Limit of the Zone",
    "Please Enter Cigarette Beetle Critical Control Limit": "Please Enter Tobacco Beetle Critical Control Limit",
    "Default cigarette beetle detections limit between 1 to 1999": "Default tobacco beetle detections limit between 1 to 1999",
    "tobacco beetle": "Tobacco Beetle",
    "cigarette beetle": "Tobacco Beetle",
    "max cigarette beetle detections allowed on the glueboard between 1 to 1999": "Max Tobacco Beetle detections allowed on the glue board (1 to 1999)",
    "max cigarette beetle detections allowed on the glueboard": "Max Tobacco Beetle detections allowed on the glue board",
    "cigarette beetle sensor": "Tobacco Beetle Sensor",
    "Cigarette Beetle Detection": "Tobacco Beetle Detection",
    "cigarette beetles": "Tobacco Beetles",
    "Tobacco Beetle Detections": "Tobacco Beetle Detections",
    "Tobacco Beetle Trend": "Tobacco Beetle Trend",
    "Cigarette Beetle Critical Control Limit": "Tobacco Beetle Critical Control Limit",
    "Tobacco Beetle Glueboard Expiry": "Tobacco Beetle Glue Board Expiry",
    "Tobacco Beetle Glueboard Detections": "Tobacco Beetle Glue Board Detections",
    "Zone Cigarette Beetle Critical Control Limit": "Zone Tobacco Beetle Critical Control Limit",
    "missing mandatory zoneName field": "Missing mandatory zoneName field",
    "Tobacco Beetle Glueboard Expiry Changed from": "Tobacco Beetle Glue Board Expiry Changed from",
    "Tobacco Beetle Glueboard Max Detections Changed from": "Tobacco Beetle Glue Board Max Detections Changed from",
    "zone cigarette beetle critical control limit changed from": "Zone Tobacco Beetle Critical Control Limit changed from",
    "cigarette beetle sensors": "Tobacco Beetle Sensors",
    "tobacco beetle glueboard max detections breached": "Tobacco Beetle Glue Board Max Detections Breached",
    "tobacco beetle glueboard expiry breached": "Tobacco Beetle Glue Board Expiry Breached",
    "tobacco beetle glueboard expiry changed": "Tobacco Beetle Glue Board Expiry Changed",
    "tobacco beetle glueboard max detections changed": "Tobacco Beetle Glue Board Max Detections Changed",
    "tobacco beetle glueboard replaced": "Tobacco Beetle Glue Board Replaced",
    "all cigarette beetle sensors": "All Tobacco Beetle Sensors",
    "Tobacco Beetle Cumulative Sum": "Tobacco Beetle Cumulative Sum",
    "detection count": "Detection Count",
    "New Tobacco Beetle Detections at Site": "New Tobacco Beetle Detections at Site",
    "New Tobacco Beetle Detections": "New Tobacco Beetle Detections",
    "zone tobacco beetle critical control limit edited": "Zone Tobacco Beetle Critical Control Limit Edited",
    "zone tobacco beetle critical control limit breached": "Zone Tobacco Beetle Critical Control Limit Breached",
    "grouped cigarette beetle detections by site": "Grouped Cigarette Tobacco Detections by Site",
    "max. detections limit on the glueboard": "Max. detections limit on the glue board",
    "please enter max. detections limit on the glueboard": "Please enter max. detections limit on the glue board",
    "max. detections limit allowed on the glueboard between 1 to 1999": "Max. detections limit allowed on the glue board between 1 to 1999",
    "enter a value between 1 to 1999": "Enter a value between 1 to 1999",
    "New Crawling Insect Detections": "New Crawling Insect Detections",
    "crawling insect sensors": "Crawling Insect Sensors",
    "Crawling Insect Detections": "Crawling Insect Detections",
    "Crawling Insect Detection": "Crawling Insect Detection",
    "Crawling Insect Trend": "Crawling Insect Trend",
    "Crawling Insect Activity": "Crawling Insect Activity",
    "crawling insect sensor": "Crawling Insect Sensor",
    "Pest activity trend is not computed for the replaced sensor(s).": "Pest activity trend is not computed for the replaced sensor(s).",
    "Pest activity trend is displayed for all the sites available.": "Pest activity trend is displayed for all the sites available.",
    "Pest activity trend is displayed for all the sites available for the customers.": "Pest activity trend is displayed for all the sites available for the customers.",
    "Search by Sensor ID": "Search by Sensor ID",
    "Search by Sensor Name": "Search by Sensor Name",
    // "No zone map found.": "No zone map found.",
    "No zone map found! Please upload the zone map to map the sensors on the map.": "No zone map found! Please upload the zone map to map the sensors on the map.",
    "max length 50": "Max Length 50",
    "max length 100": "Max Length 100",
    "max length 30": "Max Length 30",
    "max length 16": "Max Length 16",
    "Default No. of days for Flying Insect Glue Board Expiry": "Default No. of days for Flying Insect Glue Board Expiry",
    "Default max. Flying Insect detections allowed on the glue board": "Default max. Flying Insect detections allowed on the glue board",
    "Flying Insect Glue Board Expiring Notification (days)": "Flying Insect Glue Board Expiring Notification (days)",
    "Default No. of days for Tobacco Beetle Glue Board Expiry": "Default No. of days for Tobacco Beetle Glue Board Expiry",
    "Default max. Tobacco Beetle detections allowed on the glue board": "Default max. Tobacco Beetle Detections allowed on the glue board",
    "Tobacco Beetle Glue Board Expiring Notification (days)": "Tobacco Beetle Glue Board Expiring Notification (days)",
    "The number of days before a glue board expires and a breach is triggered.": "The number of days before a glue board expires and a breach is triggered.",
    "The maximum detections allowed on a glue board before a breach is triggered for the sensor.": "The maximum detections allowed on a glue board before a breach is triggered for the sensor.",
    "This setting defines the early notification generation - 'X' number of days before the expiry of the glue board.": "This setting defines the early notification generation - 'X' number of days before the expiry of the glue board.",
    "The maximum rodent detection limit for a zone before a breach is triggered.": "The maximum rodent detection limit for a zone before a breach is triggered.",
    "The maximum crawling insect detection limit for a zone before a breach is triggered.": "The maximum crawling insect detection limit for a zone before a breach is triggered.",
    "The maximum flying insect detection limit for a zone before a breach is triggered.": "The maximum flying insect detection limit for a zone before a breach is triggered.",
    "The maximum tobacco beetle detection limit for a zone before a breach is triggered.": "The maximum tobacco beetle detection limit for a zone before a breach is triggered.",
    "value": "Value",
    "edit setting value": "Edit Setting Value",
    "Deleting the zone map will reset any existing sensor's mapping on the zone map. Are you sure?": "Deleting the zone map will reset any existing sensor's mapping on the zone map. Are you sure?",
    "mapped sensors": "Mapped Sensors",
    "unmapped sensors": "Unmapped Sensors",
    "save": "Save",
    "Click & drag each sensor onto the zone floor plan to assign it to the desired location.": "Click & drag each sensor onto the zone floor plan to assign it to the desired location.",
    "Error occurred during mapping the sensor. Please try again.": "Error occurred during mapping the sensor. Please try again.",
    "updated successfully!": "Updated Successfully!",
    "view zone map": "View Zone Map",
    "upload zone map": "Upload Zone Map",
    "please enter No. of days for the glueboard expiry notification": "Please enter No. of days for the glue board expiry notification",
    "Default No. of days for the glueboard expiry notification between 1 to 90": "Default No. of days for the glue board expiry notification between 1 to 90",
    "please enter No. of days for the tobacco beetle glueboard expiry notification": "Please enter No. of days for the tobacco beetle glue board expiry notification",
    "Default No. of days for the tobacco beetle glueboard expiry notification between 1 to 90": "Default No. of days for the tobacco beetle glue board expiry notification (1 to 90)",
    "last seen / alerts": "Last Seen / Alerts",
    "security": "Security",
    "This setting specifies the number of days for a password to get expired for a user after the user logs into the application for the first time or when it is last changed.": "This setting specifies the number of days that may elapse before the password expires.",
    "This setting determines if the user password should expire or not.": "This setting determines if the user password should expire or not.",
    "Password expiry (days)": "Password expiry (days)",
    "Enable password expiry": "Enable password expiry",
    "please enter password expiry (days)": "Please enter password expiry (days)",
    "password expiry (days) Min value: 90  to Max: 730": "Password expiry (days) Min value: 90  to Max: 730",
    "please change your password to continue.": "Please change your password to continue.",
    "already added to the service team": "already added to the service team",
    "undo revoke": "Undo Revoke",
    "This setting defines the early notification generation - \"X\" number of days before the expiry of the glue board.": "This setting defines the early notification generation - \"X\" number of days before the expiry of the glue board.",
    "enabled": "Enabled",
    "disabled": "Disabled",
    "Enable / Disable password expiry": "Enable / Disable password expiry",
    "Please your enter username": "Please your enter username",
    "When enabled, this setting specifies the number of days for passwords to get expired since it was created or changed. When the password expiry is enabled (if disabled before), it is reset on the next successful login for all users.": "When enabled, this setting specifies how long a password remains valid since it was created or changed. When password expiry is enabled (if disabled before), it is reset on the next successful login for all users.",
    "No unmapped sensors exist!": "No unmapped sensors exist!",
    "Your password has expired! Please change your password to continue.": "Your password has expired! Please change your password to continue.",
    "tobacco beetle glueboard expiring": "Tobacco Beetle Glue Board Expiring",
    "flying insect glueboard expiring": "Flying Insect Glue Board Expiring",
    "Fly Glue Board expiring in": "Flying Insect Glue Board expiring in",
    "Cigarette Beetle Glue Board expiring in": "Tobacco Beetle Glue Board expiring in",
    "Customer cannot be deleted, as it is associated with one or more sites.": "Customer cannot be deleted, as it is associated with one or more sites.",
    "zone rodent critical control limit breached": "​Zone Rodent Critical Control Limit Breached",
    "update site": "Update Site",
    "do you want to remove": "Do You Want To Remove",
    "Rodent": "Rodent",
    "Flying Insect": "Flying Insect",
    "Tobacco Beetle": "Tobacco Beetle",
    "no records found" : "No records found",
    "Your password has been changed successfully! Please login with your new password.": "Your password has been changed successfully! Please login with your new password.",
    "Invalid username or password. Please enter valid credentials and try again!": "Invalid username or password. Please enter valid credentials and try again!",
    "Inactive user!": "Inactive user!",
    "The new password cannot be the same as the current password.": "The new password cannot be the same as the current password.",
    "The current password entered is incorrect!": "The current password entered is incorrect!",
    "sensors info" : "Sensors Info",
    "breached" : "Breached",
    "help": "Help",
    "Internet of Pests Help Center": "Internet of Pests Help Center",
    "Go to IoP Dashboard": "Go to IoP Dashboard",
    "sensors list": "Sensors List",
    "The Comm. Status Column": "The Comm. Status Column",
    "All sensors communicate with the IoP® platform whenever it detects “pests or in each heartbeat”. The heartbeat time for rodent and crawling sensors is 24 hrs. and for fly and cigarette beetle sensors is 30 mins.": "All sensors communicate with the IoP® platform whenever it detects “pests or in each heartbeat”. The heartbeat time for rodent and crawling sensors is 24 hrs. and for flying insect and tobacco beetle sensors is 30 mins.",
    "The Comm. Status or Communication Status lets you know what is the current status of a sensor and whether it’s functional. There are 4 possible values for comm. status:": "The Comm. Status or Communication Status lets you know the current status of a sensor and whether it’s functional. There are 4 possible values for comm. status:",
    "Displays when a sensor is newly added to your account and has not yet been switched “On”.": "Displays when a sensor is newly added to your account and has not yet been switched “On”.",
    "Displays when a sensor is online and has detected any pest or has sent a “heartbeat” message in the last 25 hours (for Rodent and Crawling Insect sensors) or in the last 40 minutes (for Fly and Cigarette Beetle sensors)": "Displays when a sensor is online and has detected a pest or has sent a “heartbeat” message in the last 25 hours (for Rodent and Crawling Insect sensors) or in the last 40 minutes (for Flying Insect and Tobacco Beetle sensors)",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message between last 25 hours and 48 hours (for Rodent and Crawling Insect sensors) or between last 40 minutes and 60 minutes (for Fly and Cigarette Beetle sensors)": "Displays when a sensor has not detected a pest or has not sent a “heartbeat” message between the last 25 hours and 48 hours (for Rodent and Crawling Insect sensors) or between the last 40 minutes and 60 minutes (for Flying Insect and Tobacco Beetle sensors)",
    "Displays when a sensor has not detected any pest or has not sent a “heartbeat” message for more than 48 hours (for Rodent and Crawling Insect sensors) or for more than 60 minutes (for Fly and Cigarette Beetle sensors)": "Displays when a sensor has not detected a pest or has not sent a “heartbeat” message for more than 48 hours (for Rodent and Crawling Insect sensors) or for more than 60 minutes (for Flying Insect and Tobacco Beetle sensors)",
    "The Last Seen/Alerts Column": "The Last Seen/Alerts Column",
    "The Last Seen/Alerts column lets you know the last time and date an update (communication) was received from the sensor. It also includes three types of alert icons.": "The Last Seen/Alerts column lets you know the last time and date an update (communication) was received from the sensor. It also includes three types of alert icons.",
    "The New Detections Column": "The New Detections Column",
    "The new detections column displays the number of “triggers” or pests the sensor has detected since the last time they were resolved. These are also displayed on the Detections List screen from where the Pest Management Personnel can resolve them.": "The New Detections column displays the number of “triggers” or pests the sensor has detected since the last time they were resolved. These are also displayed on the Detections List screen from where the Field Technician can resolve them.",
    "How often does the data on the sensor’s list page refresh?": "How often does the data on the sensor’s list page refresh?",
    "By default, the page will refresh automatically when the sensor detects pests or changes it's communication status.": "By default, the page will refresh automatically when the sensor detects pests or changes it's communication status.",
    "sensors list help": "Sensors List Help",
    "Sensor Communication Channel": "Sensor Communication Channel",
    "LPWAN or Wi-Fi - if a sensor is communicating over an LPWAN network or is configured to communicate over a local Wi-Fi network.": "LPWAN or Wi-Fi - if a sensor is communicating over an LPWAN network or is configured to communicate over a local Wi-Fi network.",
    "Critical Battery Status": "Critical Battery Status",
    "Breach Status": "Breach Status",
    "when a zone with the specific pest type has exceeded (breached) the detections or Critical Control Limit for a specific pest.": "When a zone with the specific pest type has exceeded (breached) the detections or Critical Control Limit for that pest.",
    "when a Fly or Cigarette Beetle sensor's glue board has expired. The glue board expiry is calculated from the date the sensor was added to the platform.": "When a Flying Insect or Tobacco Beetle sensor's glue board has expired. The glue board expiry is calculated from the date the sensor was added to the platform.",
    "when a Fly or Cigarette Beetle sensor's max detections limit on the glue board has exceeded.": "When a Flying Insect or Tobacco Beetle sensor's max detection limit on the glue board has been exceeded.",
    "Rodent and Crawling Insect sensors contains a lithium-ion non rechargeable cell that is permanently pre-installed for the 7 years or 12,000 transmissions expected operational lifetime.": "Rodent and Crawling Insect sensors contains a lithium-ion non rechargeable cell that is permanently pre-installed for the 7 years or 12,000 transmissions expected operational lifetime.",
    "A critical battery status is displayed when a sensor's battery is below 10%. This percentage value is calculated based on the total number of “triggers” generated by the sensor; i.e. (12,000 - Total Triggers) %.": "A critical battery status is displayed when a sensor's battery is below 10%. This percentage value is calculated based on the total number of “triggers” generated by the sensor; i.e. (12,000 - Total Triggers) %.",
    "The total triggers is maintained from the time the sensor was first turned on.": "The total number of triggers is maintained from the time the sensor was first turned on.",
    "7 Days" : "7 Days",
    "30 Days" : "30 Days",
    "no matches": "No matches",
    "valid format JPG/JPEG/PNG": "Valid format JPG/JPEG/PNG",
    "max file size: 5MB": "Max file size: 5MB",
    "Filter data by selecting the legends": "Filter Data by selecting the legends",
    "day vs day": "Day vs Day",
    "week vs week": "Week vs Week",
    "month vs month": "Month vs Month",
    "year vs year": "Year vs Year",
    "Aggregated for": "Aggregated for",
    "Filters": "Filters",
    "zone map successfully updated.": "Zone Map Successfully Updated.",
    "English" : "English",
    "Japanese" : "日本",
    "Spanish" : "Español",
    "submit" : "Submit",
    "Did you forgot your password?" : "Did you forgot your password?",
    "Submit your Username or registered Email ID and you will receive a temporary password to login." : "Submit your Username or registered Email ID and you will receive a temporary password to login.",
    "Back to Sign in" : "Back to Sign in",
    "Temporary password has been sent to your email successfully." : "Temporary password has been sent to your email successfully.",
    "Invalid username/email id" : "Invalid username/email id",
    "Username / Email ID" : "Username / Email ID",
    "please enter your username / email id": "Please Enter Your Username / Email ID",
    "site pest detections report" : "Site Pest Detections Report",
    "No unMapped sensor" : "No unmapped sensor",
    "Hourly cumulative detections" : "Hourly Cumulative Detections",
    "Percentage detections" : "Percentage Detections",
    "Sensor name" : "Sensor name",
    "count" : "Count",
    "total detections" : "Total detections",
    "Please select the customer, site, date range and time zone to generate the report." : "Please select the customer, site, date range and time zone to generate the report.",
    "system error!" : "System Error!",
    "download" : "Download",
    "site address" : "Site Address",
    "site timezone" : "Site Timezone",
    "Pest Management Company" : "Pest Management Company",
    "Evoscien Main Office" : "Evoscien Main Office",
    "generated on" : "Generated On",
    "generated by" : "Generated By",
    "Date range" : "Date Range",
    "Time zone" : "Time Zone",
    "Powered by" : "Powered by",
    "This report was generated" : "This report was generated",
    "Percentage change" : "Percentage Change",
    "generate report" : "Generate Report",
    "stakeholder performance analysis" : "Stakeholder Performance Analysis",
    "Sort by Site" : "Sort by Site",
    "Sort by Zone" : "Sort by Zone",
    "Week I: Starting Date Of" : "Week I: Starting Date Of",
    "Week II: Starting Date Of" : "Week II: Starting Date Of",
    "Please select the customer, pest and comparison type and time zone to generate the report." : "Please select the customer, pest and comparison type and time zone to generate the report.",
    "deleted" : "Deleted",
    "original day" : "Original Day",
    "final day" : "Final Day",
    "Original Day should be less than Final Day" : "Original Day should be earlier than Final Day",
    "Final Day should be greater than Original Day" : "Final Day should be later than Original Day",
    "original week" : "Original Week",
    "final week" : "Final Week",
    "Original Week should be less than Final Week" : "Original Week should be earlier than Final Week",
    "Final Week should be greater than Original Week" : "Final Week should be later than Original Week",
    "original month" : "Original Month",
    "final month" : "Final Month",
    "Original Month should be less than Final Month" : "Original Month should be earlier than Final Month",
    "Final Month should be greater than Original Month": "Final Month should be later than Original Month",
    "original year" : "Original Year",
    "final year" : "Final Year",
    "Original Year should be less than Final Year" : "Original Year should be earlier than Final Year",
    "Final Year should be greater than Original Year" : "Final Year should be later than Original Year",
    "Please select the customer and pest to generate the report." : "Please select the customer and pest to generate the report.",
    "progress" : "Progress",
    "success" : "Success",
    "failed" : "Failed",
    "retry" : "Retry",
    "status" : "Status",
    "percentage detections" : "Percentage Detections",
    "faqs" : "FAQs",
    "What is remote pest monitoring? Why is it important?" : "What is remote pest monitoring? Why is it important?",
    "Remote pest monitoring technology allows pest management professionals to track and monitor pest activity from anywhere in real-time via mobile or web-based platforms. It allows pest control technicians to quickly identify and treat hot spots on the premises, potentially reducing labour time and chemical use." : "Remote pest monitoring technology allows pest management professionals to track and monitor pest activity from anywhere in real-time via mobile or web-based platforms. It allows pest control technicians to quickly identify and treat hot spots on the premises, potentially reducing labour time and chemical use.",
    "What is the point of using sensors without a trap?" : "What is the point of using sensors without a trap?",
    "The sensor’s flexible form factor allows it to be placed in usually inaccessible areas, such as roof spaces and ventilation shafts, where they can provide early warning of potential pest breeding sites and threats to infrastructure." : "The sensor’s flexible form factor allows it to be placed in usually inaccessible areas, such as roof spaces and ventilation shafts, where they can provide early warning of potential pest breeding sites and threats to infrastructure.",
    "Can a single sensor detect rodents, crawling, and flying insects?" : "Can a single sensor detect rodents, crawling, and flying insects?",
    "No. While the underlying technology is the same, each sensor is designed for a particular pest type." : "No. While the underlying technology is the same, each sensor is designed for a particular pest type.",
    "Will my sensor battery last 7 years? Are the batteries replaceable?" : "Will my sensor battery last 7 years? Are the batteries replaceable?",
    "The expected battery life of the rodent and crawling pest sensors is 7 years or 12,000 transmissions. Actual battery life would vary depending on the frequency of pest activity in the area. Batteries can only be replaced by a trained technician." : "The expected battery life of the rodent and crawling pest sensors is 7 years or 12,000 transmissions. Actual battery life would vary depending on the frequency of pest activity in the area. Batteries can only be replaced by a trained technician.",
    "Flying insect sensors are powered by the main power supply, and do not require batteries." : "Flying insect sensors are powered by the main power supply, and do not require batteries.",
    "Do I need Wi-Fi for my IoP® setup?" : "Do I need Wi-Fi for my IoP® setup?",
    "A Wi-Fi or ethernet connection is only required for the local gateway to receive signals from the rodent and crawling pest sensors. Wi-Fi is preferred for flying insect sensors, but they can transmit data to rodents and crawling insect gateways." : "A Wi-Fi or ethernet connection is only required for the local gateway to receive signals from the rodent and crawling pest sensors. Wi-Fi is preferred for flying insect sensors, but they can transmit data to rodents and crawling insect gateways.",
    "Since data transmission is minimal, low-data pre-paid SIMs can be used if local Wi-Fi or ethernet connections are unavailable." : "Since data transmission is minimal, low-data pre-paid SIMs can be used if local Wi-Fi or ethernet connections are unavailable.",
    "Can I add, relocate, or remove sensors if needed?" : "Can I add, relocate, or remove sensors if needed?",
    "Yes, the IoP® system allows seamless sensor addition, removal, replacement, and relocation without data loss. For more information, refer to the Technician’s Handbook." : "Yes, the IoP® system allows seamless sensor addition, removal, replacement, and relocation without data loss. For more information, refer to the Technician’s Handbook.",
    "What do I do if my sensors stop working?" : "What do I do if my sensors stop working?",
    "In the absence of pest activity, sensors transmit a heartbeat every 24 hours to indicate their functionality. Contact your provider to repair or replace the sensor if no heartbeat signal is received, and the sensor status changes to idle or offline.": "In the absence of pest activity, sensors transmit a heartbeat every 24 hours to indicate their functionality. Contact your provider to repair or replace the sensor if no heartbeat signal is received, and the sensor status changes to idle or offline.",
    "How do I sign up for an IoP® account?" : "How do I sign up for an IoP® account?",
    "Your IoP® super-admin account will be generated upon confirmation of your order. Contact our support team for more information, or to request a demo." : "Your IoP® super-admin account will be generated upon confirmation of your order. Contact our support team for more information, or to request a demo.",
    "I’ve received a breach alert. What do I do next?" : "I’ve received a breach alert. What do I do next?",
    "Critical control limits refer to the acceptable level of pest activity. The values can be manually adjusted per pest according to customer requirements and/or local regulations. An alert is sent when sensor detections exceed the critical control limit. Breach alerts call for immediate action by your pest control service provider." : "Critical control limits refer to the acceptable level of pest activity. The values can be manually adjusted per pest according to customer requirements and/or local regulations. An alert is sent when sensor detections exceed the critical control limit. Breach alerts call for immediate action by your pest control service provider.",
    "What type of alerts does the IoP® platform provide?" : "What type of alerts does the IoP® platform provide?",
    "The IoP® platform sends real-time alerts for critical control limit breaches and sensor communication status updates, including idle/offline/active, site-related alerts like site access granted, site detections resolved, and zone-related alerts when pest detections are resolved in a zone." : "The IoP® platform sends real-time alerts for critical control limit breaches and sensor communication status updates, including idle/offline/active, site-related alerts like site access granted, site detections resolved, and zone-related alerts when pest detections are resolved in a zone.",
    "How does IoP® help identify pest entry points?" : "How does IoP® help identify pest entry points?",
    "IoP® sensors provide valuable insight into pest movement patterns by recording which side of the sensor the pest contacted first. Taken together, this data can help PMPs to identify and target potential breeding sites and points of entry." : "IoP® sensors provide valuable insight into pest movement patterns by recording which side of the sensor the pest touched first. Taken together, this data can help PMPs to identify and target potential breeding sites and points of entry.",
    "Does the IoP® platform provide documentation for regulatory compliance?" : "Does the IoP® platform provide documentation for regulatory compliance?",
    "Yes, all pest activity is securely stored and accessible on the IoP® platform anytime. Users can generate and view historical and current pest activity reports to demonstrate regulatory compliance." : "Yes, all pest activity is securely stored and accessible on the IoP® platform anytime. Users can generate and view historical and current pest activity reports to demonstrate regulatory compliance.",
    "Where can the sensors be used?" : "Where can the sensors be used?",
    "The sensors can be deployed in areas prone to pest activity, like kitchens, storage areas, waste disposal areas, etc. They can also be used in hard-to-access areas like roof spaces and ventilation ducts." : "The sensors can be deployed in areas prone to pest activity, like kitchens, storage areas, waste disposal areas, etc. They can also be used in hard-to-access areas like roof spaces and ventilation ducts.",
    "Is the sensor waterproof?" : "Is the sensor waterproof?",
    "Yes, the sensor is IP67 rated, but we recommend turning the unit off and drying it thoroughly before reuse to reduce the possibility of false detections." : "Yes, the sensor is IP67 rated, but we recommend turning the unit off and drying it thoroughly before reuse to reduce the possibility of false detections.",
    "If you have more queries please click below our official website" : "If you have more queries please click below our official website",
    "Follow us on" : "Follow us on",
    "Download Apps" : "Download Apps",
    "User Guides" : "User Guides",
    "IoP® Platform User Manual" : "IoP® Platform User Manual",
    "IoP® Technician’s Handbook" : "IoP® Technician’s Handbook",
    "Frequently Asked Questions" : "Frequently Asked Questions",
    "Visit" : "Visit",
    "IoP® website" : "IoP® website",
    "for more information" : "for more information",
    "Sensor detected possible pest shelter or entrance point." : "Sensor detected possible pest shelter or entrance point.",
    "Sensors detected possible food source or entrance point." : "Sensors detected possible food source or entrance point.",
    "Error: Unable to connect to the backend server." : "Error: Unable to connect to the backend server.",
    "Sensor type not enabled: Crawling Insect" : "Sensor type not enabled: Crawling Insect",
    "Sensor type not enabled: Rodent" : "Sensor type not enabled: Rodent",
    "Sensor type not enabled: Fly" : "Sensor type not enabled: Flying Insect",
    "Sensor type not enabled: Cigarette Beetle" : "Sensor type not enabled: Tobacco Beetle",
    "Zone Events" : "Zone Events",
    "zone live monitoring disabled" : "Zone Live Monitoring Disabled",
    "zone live monitoring enabled" : "Zone Live Monitoring Enabled",
    "live monitoring toggle" : "Live Monitoring Toggle",
    "live monitoring" : "Live Monitoring",
    "live monitoring disabled" : "Live Monitoring Disabled",
    "live monitoring enabled" : "Live Monitoring Enabled",
    "enable live monitoring" : "Enable Live Monitoring",
    "Enabling live monitoring will allow pest detections and alerts in real-time from being updated on the platform." : "Enabling live monitoring will allow pest detections and alerts in real-time to be updated on the platform.",
    "I confirm that I have turned ON all the sensors and they are reporting Active." : "I confirm that I have turned ON all the sensors and they are reporting Active.",
    "successfully enabled the live monitoring" : "Successfully enabled the live monitoring",
    "disable live monitoring" : "Disable Live Monitoring",
    "Disabling live monitoring will ignore any pest detections that would be sent by the sensors and will prevent real-time detections and alerts from being updated on the platform." : "Disabling live monitoring will ignore any pest detections that would be sent by the sensors and will prevent real-time detections and alerts from being updated on the platform.",
    "I confirm to disable live monitoring for this zone." : "I confirm that I want to disable live monitoring for this zone.",
    "Daily heartbeat and power ON messages will NOT be ignored and the application will continue to update the Communication Status and Last Seen Timestamp for all the sensors in this zone." : "Daily heartbeat and power ON messages will NOT be ignored and the application will continue to update the Communication Status and Last Seen Timestamp for all the sensors in this zone.",
    "successfully disabled the live monitoring" : "Successfully disabled the live monitoring",
    "sensor event rejected due to disabled zone live monitoring" : "Sensor Event Rejected due to Disabled Zone Live Monitoring",
    "Warning! Zone Live monitoring is currently disabled. Last sensor detection was" : "Warning! Zone Live Monitoring is currently disabled. Last sensor detection was",
    "Enable Live Monitoring for the zone to start logging  sensor detections." : "Enable Live Monitoring for the zone to start logging  sensor detections.",
    "The following sensors are not Active. Please cycle the sensor power and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone." : "The following sensors are not Active. Please cycle the sensor power and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone.",
    "I acknowledge the above issues and still want to enable live monitoring." : "I acknowledge the above issues and still want to enable live monitoring.",
    "The following Active sensors were last seen before Live Monitoring for this Zone was Disabled on" : "The following Active sensors were last seen before Live Monitoring for this Zone was Disabled on",
    ". If you may have switched OFF the following sensors, then please switch them back ON and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone." : ". If you have switched OFF the following sensors,  please switch them back ON and ensure that they are reporting Active in the application before you Enable Live Monitoring for this zone.",
    "remove floor map" : "Remove Floor Map",
    "Live Monitoring" : "Live Monitoring",
    "for the zone where you are adding the sensor is" : "for the zone where you are adding the sensor is",
    "Disabled" : "Disabled",
    "Please" : "Please",
    "Enable" : "Enable",
    "after you have added and turned ON all the sensors in this zone to monitoring pest detections" : "after you have added and turned ON all the sensors in this zone to monitoring pest detections",
    "Remove Zone Map" : "Remove Zone Map",
    "Live Monitoring for this zone is" : "Live Monitoring for this zone is",
    "disabled." : "disabled.",
    "After you have added and turned ON all the sensors in this zone;" : "After you have added and turned ON all the sensors in this zone;",
    "enable " : "enable ",
    "Live Monitoring to monitor pest detections." : "Live Monitoring to monitor pest detections.",
    "Disabled Live Monitoring Zones" : "Disabled Live Monitoring Zones",
    "Zone Map Removed Successfully" : "Zone Map Removed Successfully",
    "Upload zone map" : "Upload zone map",
    "No zone map found!" : "No zone map found!",
    "last updated timestamp" : "Last Updated Timestamp",
    "Zone Map Uploaded Successfully" : "Zone Map Uploaded Successfully",
    "all roles" : "All Roles",
    "no detections reported!" : "No Detections Reported!",
    "Removing the zone map will un-map all the currently mapped sensor and delete the zone map. Are you sure you want to remove the zone map?" : "Removing the zone map will un-map all the currently mapped sensor and delete the zone map. Are you sure you want to remove the zone map?",
    "Successfully added sensor(s)." : "Successfully added sensor(s).",
    "Click here" : "Click here",
    "to map these sensors on the zone map." : "to map these sensors on the zone map.",
    "has been automatically" : "has been automatically",
    "for the" : "for the",
    "click here " : "click here ",
    "live monitoring for this zone only after turning" : "live monitoring for this zone only after turning",
    "zone to prevent any false detections during handling of the sensors." : "zone to prevent any false detections during handling of the sensors.",
    "ON" : "ON",
    "the newly added sensors." : "the newly added sensors.",
    "is not mapped on the zone map" : "is not mapped on the zone map",
    "latest sensor image" : "Latest Sensor Image", 
    "Username must be 5-20 characters long and must not include any spaces." : "Username must be 5-20 characters long and must not include any spaces.",
    "please enter phone number" : "Please enter phone number",
    "flying insect glueboard replaced" : "Flying insect Glue Board Replaced",
    "First Name must be between 2 and 50 chars." : "First Name must be between 2 and 50 chars.",
    "Last Name must be between 2 and 50 chars." : "Last Name must be between 2 and 50 chars.",
    "Customer Name must be between 2 and 50 chars." : "Customer Name must be between 2 and 50 chars.",
    "Street address  must be between 5 and 250 chars." : "Street address  must be between 5 and 250 chars.",
    "City must be between 2 and 50 chars." : "City must be between 2 and 50 chars.",
    "State must be between 2 and 50 chars." : "State must be between 2 and 50 chars.",
    "Country must be between 2 and 50 chars." : "Country must be between 2 and 50 chars.",
    "Postal code must be between 4 and 10 chars." : "Postal code must be between 4 and 10 chars.",
    "Name must be between 2 and 50 chars." : "Name must be between 2 and 50 chars.",
    "Site Name must be between 2 and 50 chars." : "Site Name must be between 2 and 50 chars.",
    "Zone name must be between 2 and 50 chars." : "Zone name must be between 2 and 50 chars.",
    "Sensor ID must be between 6 and 16 chars." : "Sensor ID must be between 6 and 16 chars.",
    "Sensor name must be between 2 and 25 chars." : "Sensor name must be between 2 and 25 chars.",
    "Description must be between 2 and 100 chars." : "Description must be between 2 and 100 chars.",
    "flying insect glueboard expiry breached" : "Flying Insect Glue Board Expiry Breached",
    "Email ID already taken. Please enter a different one." : "Email ID already taken. Please enter a different one.",
    "sensor scanned" : "Sensor Scanned",
    "scanned" : "Scanned",
    "flying insect sensor": "Flying Insect Sensor",
    "personalization" : "Personalization",
    "setting value options" : "Setting Value Options",
    "Indicates the format that dates are displayed on the web and mobile applications." : "Indicates the format that dates are displayed on the web and mobile applications.",
    "Indicates the format that times are displayed on the web and mobile applications." : "Indicates the format that times are displayed on the web and mobile applications.",
    "Date Format" : "Date Format",
    "Time Format" : "Time Format",
    "please enter a valid phone number" : "Please enter a valid phone number",
    "Timestamp" : "Timestamp",
    "The entry points can only be determined with 2 or more sensors." : "The entry points can only be determined with 2 or more sensors.",
    "edit zone" : "Edit Zone",
    "add zone" : "Add Zone",
    "Zone Created Successfully" : "Zone Created Successfully",
    "Zone Updated Successfully" : "Zone Updated Successfully",
    "You've reached the maximum limit of 30 zones that can be created." : "You've reached the maximum limit of 30 zones that can be created.",
    "no zone available" : "No Zone Available",
    "to add a zone." : "To dd a Zone.",
    
    "Customer/Site Access Control": "Customer/Site Access Control",
    "Stakeholder Performance Report" : "Stakeholder Performance Report",
    "Pest Infestation Summary Report" : "Pest Infestation Summary Report",
    "Hours Distribution Report" : "Hours Distribution Report",
    "Time Series Report" : "Time Series Report",
    "Cumulative Sum Report" : "Cumulative Sum Report",
    "Time Series Comparison Report" : "Time Series Comparison Report",
    "vs" : "vs",
    "Download PDF File" : "Download PDF File",
    "group by" : "Group By",
    
    "export" : "Export",
    "Remaining Battery" : "Remaining Battery",
    "Estimated Battery Life" : "Estimated Battery Life",
    "The Remaining battery percentage is calculated as: ((Maximum transmissions i.e. 12,000 - Received transmissions ) / Maximum transmissions ) × 100. This shows the remaining capacity of the sensor's battery based on its total usage." : "The Remaining battery percentage is calculated as: ((Maximum transmissions i.e. 12,000 - Received transmissions ) / Maximum transmissions ) × 100. This shows the remaining capacity of the sensor's battery based on its total usage.",
    "Estimated battery life is calculated using the daily transmission rate of 20 transmissions/day, derived from the total transmissions over the last 90 days divided by 90." : "Estimated battery life is calculated using the daily transmission rate of 20 transmissions/day, derived from the total transmissions over the last 90 days divided by 90.",
    "Estimated battery life is calculated using the daily transmission rate, derived from the total transmissions over the last 90 days divided by 90." : "Estimated battery life is calculated using the daily transmission rate, derived from the total transmissions over the last 90 days divided by 90.",
    "months" : "Months",
    "Transmissions Overview" : "Transmissions Overview",
    "The total number of transmissions received from the sensor since it was first added to the account." : "The total number of transmissions received from the sensor since it was first added to the account.",
    "Remaining transmissions are calculated by subtracting the total transmissions received from the sensor from the maximum allowable limit of 12,000." : "Remaining transmissions are calculated by subtracting the total transmissions received from the sensor from the maximum allowable limit of 12,000.",
    "The daily rate is calculated by dividing the total number of transmissions received from the sensor over the last 3 months by 90 days." : "The daily rate is calculated by dividing the total number of transmissions received from the sensor over the last 3 months by 90 days.",
    "Right" : "Right",
    "Left" : "Left",
    "Center" : "Center",
    "Received" : "Received",
    "Daily Rate" : "Daily Rate",
    "transmission/day" : "transmission/day",
    "transmissions/day" : "transmissions/day"
 }

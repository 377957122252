import { apiData } from './../../../../../common';
import { AuthenticationService } from './../../../../../helpers/authentication.service';
import { LoginService } from './../../../../login/login.service';
import { Component, OnInit, Inject, ElementRef } from '@angular/core';
import { ProfileService } from '../../../profile/profile.service';
import { SESSION_STORAGE, StorageService, LOCAL_STORAGE } from 'angular-webstorage-service';
import { Status } from 'src/app/helpers/config/status';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
import { saveAs } from 'file-saver';
import * as _ from 'lodash';
import { FormControl, FormGroup } from '@angular/forms';
import { ThemeService } from 'src/app/helpers/services/theme.service';
import { theme } from 'src/app/classModel/themes';
import { filter } from 'rxjs/operators';
import { DashboardService } from '../../dashboard.service';

//import { timingSafeEqual } from 'crypto';
//import  logo  from './../../../../../../assets/images/logos/Home.png';
declare const require: any;
const USER = 'user';
const MENULIST = 'menulist';
declare var $: any;

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss']
})
export class TopBarComponent implements OnInit {

  base_url = './../../../../../../assets/images/iconSet/';
  notificationRedDotFlag: boolean = false;
  notificationCounts = 0;
  toggleNotificationFlag: boolean = false;

  clientLogo = require('./../../../../../../assets/images/logos/calmic logo.png');
  userImg = require('./../../../../../../assets/images/logos/user.png');
  logout = require('./../../../../../../assets/images/iconSet/shut-down-icon.svg');
  BBlogo = require('./../../../../../../assets/images/logos/IoP-Logo.svg');
  logo = require('./../../../../../../assets/images/logos/Dubai-Municipality.svg');

  roleAliasName: string; //= 'Superadmin';
  userName: string;
  displayFullName: string;
  boneCustomerName: string;
  // getBOneLogo: string;
  appProfileUrl = '/home/profile/profile';
  appLanguageUrl = '/home/profile/changePassword';
  appCustomersUrl = '/home/customers';
  appSettings = '/home/settings';
  appUser = '/home/users';

  subProfilePic: any;
  navbarCollapseElem: any;
  navbarTogglerElem: any;
  navbarSubmenuCollapseElem: any;

  files: File;
  toggleThemeMood: string;
  tempStorag: any;
  storage: any;
  mouseHover: boolean = false;
  activeIcon: string;
  UserListBtnPermission: boolean;

  // homeButtonPng ="Home.png"
  MenuList: any[]; 

    // {
    //   name: 'users', url: '/home/users', iconName: 'icon-users', is_active: false, is_hover: false,
    //   isEnabled: this._authService.getPermission(['Users', '11', 'View'])
    // },
    // {
    //   name: 'users', url: '#', iconName: 'icon-users', is_active: false, sub: [
    //     {
    //       name: 'user list', url: '/home/users', is_active: false,
    //       isEnabled: this._authService.getPermission(['Users', '11', 'View'])
    //     },
    //     {
    //       name: 'add user', url: '/home/users/addUser', is_active: false,
    //       isEnabled: this._authService.getPermission(['Users', '11', 'View'])
    //     },
    //     {
    //       name: 'roles', url: '/home/roles', is_active: false,
    //       isEnabled: this._authService.getPermission(['Roles', '14', 'View'])
    //     }
    //   ], is_hover: false
    // }
    // {
    //   name: 'calendar', url: '/home/calendar', iconName: 'icon-calendar-icon', is_active: false, is_hover: false,
    //   isEnabled: this._authService.getPermission(['CALENDAR', 'CALENDAR', 'View'])
    // },
    // {
    //   name: 'pest detect', url: '/home/pestDetect', iconName: 'icon-rodent', is_active: false, is_hover: false,
    //   isEnabled: true
    // }
    // this._authService.getPermission(['CALENDAR', 'CALENDAR', 'View'])
    // { name: 'Blog', url: '/home', activeIcon: this.blogActiveIcon, inActiveIcon: this.blogInactiveIcon, is_active: false, is_hover: false }
  dropdownToggle: any;

  constructor(private _dashboardService: DashboardService,private _loginService: LoginService, private _authService: AuthenticationService, private _profileService: ProfileService, private _router: Router, private _themeService: ThemeService, private _commonDataService: CommonDataService, @Inject(SESSION_STORAGE) private _storage: StorageService, @Inject(LOCAL_STORAGE) private _localStorage: StorageService, private _activatedRoute: ActivatedRoute, private elRef: ElementRef) {

    this.storage = this._authService.toggleStorage();

    this.MenuList = [
      { name: 'home', url: '/home', iconName: 'icon-home', is_active: true, is_hover: false, isEnabled: true },
      {
        name: 'detections', url: '/home/detections', iconName: 'icon-detections', is_active: false, is_hover: false,
        isEnabled: this._authService.getPermission(['Detections', '2', 'View'])
      },
      {
        name: 'sensors', url: '/home/sensors', iconName: 'icon-sensors', is_active: false, is_hover: false,
        isEnabled: this._authService.getPermission(['Sensors', '4', 'View'])
      },
      {
        name: 'sites', url: '/home/sites', iconName: 'icon-site', is_active: false, is_hover: false,
        isEnabled: this._authService.getPermission(['Sites', '17', 'View'])
      },
      {
        name: 'reports', iconName: 'icon-reports', is_active: false, is_hover: false,
        sub: [
          {
            name: 'site pest detections report', url: '/home/reports/sitePestDetections', is_active: false,
            isEnabled: this._authService.getPermission(['Reports', '10', 'View']),
          },
          {
            name: "stakeholder performance analysis", url: '/home/reports/stakeHolder', is_active: false,
            isEnabled: this._authService.getPermission(['Reports', '10', 'View']),
          },
          {
            name: 'pest infestation summary', url: '/home/reports/pestInfestation', is_active: false,
            isEnabled: this._authService.getPermission(['Reports', '10', 'View'])
          },
          {
            name: 'pest detections report', url: '/home/reports/pestDetections/hoursDistribution', is_active: false,
            isEnabled: this._authService.getPermission(['Reports', '10', 'View']),
            sub: [
            {
              name: 'Hours Distribution', url: '/home/reports/pestDetections/hoursDistribution', is_active: false,
              isEnabled: this._authService.getPermission(['Reports', '10', 'View'])
            },{
              name: 'Time Series', url: '/home/reports/pestDetections/timeSeries', is_active: false,
              isEnabled: this._authService.getPermission(['Reports', '10', 'View'])
            },{
              name: 'Cumulative Sum', url: '/home/reports/pestDetections/cumulativeSum', is_active: false,
              isEnabled: this._authService.getPermission(['Reports', '10', 'View'])
            }]
          },
          {
            name: 'time series comparison', url: '/home/reports/timeSeries', is_active: false,
            isEnabled: this._authService.getPermission(['Reports', '10', 'View'])
          }
          // {
          //   name: 'hygiene analysis', url: '/home/reports/hygieneAnalysis', is_active: false,
          //   isEnabled: this._authService.getPermission(['Reports', '10', 'View'])
          // }
        ],
        isEnabled: this._authService.getPermission(['Reports', '10', 'View'])
      },
      {
        name: 'administration', iconName: 'icon-administration', is_active: false, is_hover: false,
        sub: [
          {
            name: 'customers', url: '/home/customers', is_active: false,
            isEnabled: this._authService.getPermission(['Customers', '7', 'View'])
          },
          {
            name: 'users', url: '/home/users', is_active: false,
            isEnabled: this._authService.getPermission(['Users', '11', 'View'])
          },
          {
            name: 'settings', url: '/home/settings', is_active: false,
            isEnabled: this._authService.getPermission(['Settings', '20', 'View'])
          }
        ],
        isEnabled: (this._authService.getPermission(['Customers', '7', 'View']) || this._authService.getPermission(['Users', '11', 'View']) || this._authService.getPermission(['Settings', '20', 'View']))
      }];
    
    let filterdMenu = this.MenuList.filter(e => {
      return e.isEnabled == true || e.isEnabled == undefined;
    });
    
    this.MenuList = filterdMenu.filter(e => {
      if (e.sub != undefined) {
        let subTemp = [];
        e.sub.some(subMenu => {
          if (subMenu.isEnabled == true) {
            subTemp.push(subMenu);
          }
        }
        )
        e.sub = subTemp;
        if (e.sub.length != 0) {
          return e;
        }
      } else {
        return e;
      }
    })

    let url: String;
    this._router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      url = this._activatedRoute.snapshot['_routerState'].url;
      this.storage = this._authService.toggleStorage();
      for (const key in this.MenuList) { // first level child
   
        if ((this.MenuList[key]['url'] == url.substring(0, url.indexOf('/detail'))) || (this.MenuList[key]['url'] == url.substring(0, url.indexOf('?'))) ||  (this.MenuList[key]['url'] == url)) {

          this.activeTobarLink(this.MenuList[key]['name']);
          this._commonDataService._sendNotificationFlag(false);

        } else {
          this.MenuList[key]['is_active'] = false;
        }
        
        if (this.MenuList[key]['sub'] !== undefined) { // second level child

          for (const key1 in this.MenuList[key]['sub']) { 
            if ((this.MenuList[key]['sub'][key1]['url'] == url.substring(0, url.indexOf('/detail'))) || this.MenuList[key]['sub'][key1]['url'] == url.substring(0, url.indexOf('?')) || this.MenuList[key]['sub'][key1]['url'] == url) {

              this.MenuList[key]['sub'][key1]['is_active'] = true;
              this.activeTobarLink(this.MenuList[key]['name']);
              this._commonDataService._sendNotificationFlag(false);

            } else {
              this.MenuList[key]['sub'][key1]['is_active'] = false;
            }

            if (this.MenuList[key]['sub'][key1]['sub'] !== undefined) { // third level child

              for (const key2 in this.MenuList[key]['sub'][key1]['sub']) { 

                if ((this.MenuList[key]['sub'][key1]['sub'][key2]['url'] == url.substring(0, url.indexOf('/detail'))) || this.MenuList[key]['sub'][key1]['sub'][key2]['url'] == url.substring(0, url.indexOf('?')) || this.MenuList[key]['sub'][key1]['sub'][key2]['url'] == url) {

                  this.MenuList[key]['sub'][key1]['sub'][key2]['is_active'] = true;
                  this.activeTobarLink(this.MenuList[key]['name']);
                  this._commonDataService._sendNotificationFlag(false);
    
                } else {
                  this.MenuList[key]['sub'][key1]['sub'][key2]['is_active'] = false;
                }

              }

            }

          }
        }
      }
    })

    this.subProfilePic = this._profileService.profilePic.subscribe(e => {
      this.userImg = JSON.parse(this.storage.get(USER)).image ?  JSON.parse(this.storage.get(USER)).image : this.userImg;
    })
    this.getNotifCount();
  }

  ngAfterViewInit() {
    this.navbarCollapseElem = this.elRef.nativeElement.querySelector('.navbar-collapse');
    this.navbarTogglerElem = this.elRef.nativeElement.querySelector('.navbar-toggler');
    this.navbarSubmenuCollapseElem = this.elRef.nativeElement.querySelector('.submenu');
    this.dropdownToggle = this.elRef.nativeElement.querySelector('.dropdown-menu');
    $('.secSubMenu').mouseenter(function(){
      $(this).find('ul.submenu.dropdown-menu').addClass('show');
    });

    $('.secSubMenu').mouseleave(function(){
      $(this).find('ul.submenu.dropdown-menu').removeClass('show');
    });

  }

  ngOnInit() {

    // this.downloadFile('pdf');
    // //this.getBOneLogo = this.logo;
    if (this.storage.get(MENULIST)) {
      this.MenuList = this.storage.get(MENULIST);
    }

    // const notificationList = JSON.parse(localStorage.getItem(`iop_notification_${this._authService.getUserName()}`));
    // const notifictaionArray = notificationList ? notificationList['list'] : null;
    // this.notificationCounts = notifictaionArray ? notifictaionArray.length : 0;

    // Red Dot Flag local storage
    const notificationItem = JSON.parse(localStorage.getItem(`iop_notification_redDotFlag_${this._authService.getUserName()}`));
    const notifictaionFlag = notificationItem ? notificationItem['redDotFlag'] : null;
    if(notifictaionFlag) {
      this.notificationRedDotFlag = true;
      this._commonDataService.clearedAllRemoveRedDotFlag.emit(true); 
    }

    // enable the red dot (silent notification)
    this._commonDataService.enableRedDotFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) { 
        localStorage.setItem(`iop_notification_redDotFlag_${this._authService.getUserName()}`, JSON.stringify({ "redDotFlag": true }));
        this.notificationRedDotFlag = true;
        this._commonDataService.clearedAllRemoveRedDotFlag.emit(true); 
      }
    });

    // red dot notification (rabbit MQ)
    this._commonDataService.silentNotificationFlag.subscribe(responseCount => {
      localStorage.setItem(`iop_notification_redDotFlag_${this._authService.getUserName()}`, JSON.stringify({ "redDotFlag": true }));
      this.notificationRedDotFlag = true;
      this._commonDataService.clearedAllRemoveRedDotFlag.emit(true); 
    });

    // clearedAllRemoveRedDotFlag
    this._commonDataService.clearedAllRemoveRedDotFlag.subscribe(flag => {
      this.notificationRedDotFlag = flag;
    });

    // notification count
    this._commonDataService.notificationCount.subscribe(responseCount => {
      
      this.notificationCounts = responseCount;

    }, error => {
      this.notificationCounts = 0;
      console.log("Error Notification count >>>", error);
    });

    // notification flag
    this._commonDataService._getNotificationFlag.subscribe(flag => {
      this.toggleNotificationFlag = flag;
    })
    
    this.tempStorag = JSON.parse(this.storage.get(USER));
    if (this.tempStorag.theme === 'default') {
      this.toggleThemeMood = 'light mood';
    } else {
      this.toggleThemeMood = 'dark mood';
    }

    this.UserListBtnPermission = this._authService.getPermission(['Users', '11', 'View']);

    this._commonDataService._getUserDetails.subscribe(flag => {
      this.userName = this._authService.getUserName();
      this.displayFullName = this._authService.getFullName();
      this.roleAliasName = JSON.parse(this.storage.get(USER)).aliasName;
      this.boneCustomerName = JSON.parse(this.storage.get(USER)).bOneCustomerName;
      this.userImg = JSON.parse(this.storage.get(USER)).image ? JSON.parse(this.storage.get(USER)).image : this.userImg;
      // this.getBOneLogo = JSON.parse(this.storage.get(USER)).bOneCustomerLogo;
    })

    this.userName = this._authService.getUserName();
    this.displayFullName = this._authService.getFullName();
    this.roleAliasName = JSON.parse(this.storage.get(USER)).aliasName;
    this.boneCustomerName = JSON.parse(this.storage.get(USER)).bOneCustomerName;
    this.userImg = JSON.parse(this.storage.get(USER)).image ? JSON.parse(this.storage.get(USER)).image : this.userImg;
    // this.getBOneLogo = JSON.parse(this.storage.get(USER)).bOneCustomerLogo;

  }

  // check red dot condition
  async getNotifCount() {
    let url = apiData.url + apiData.home.notificationCount;
    await this._dashboardService.getNotificationCount(url).subscribe((res: any) => {
      this._commonDataService._sendNotificationCount(res);
      if (res.status = Status.OK) {
        if(res.PopupNotificationsCount) {
          localStorage.setItem(`iop_notification_redDotFlag_${this._authService.getUserName()}`, JSON.stringify({ "redDotFlag": true }));
          this.notificationRedDotFlag = true;
        } else {
          localStorage.removeItem(`iop_notification_redDotFlag_${this._authService.getUserName()}`);
          this.notificationRedDotFlag = false;
        }
      } else {
        localStorage.removeItem(`iop_notification_redDotFlag_${this._authService.getUserName()}`);
        this.notificationRedDotFlag = false;
        console.log("error >>", res);
      }
    }, error=> {
      console.log("error >>", error);
      localStorage.removeItem(`iop_notification_redDotFlag_${this._authService.getUserName()}`);
    });
  }

  signOut() {
    this._loginService.logOut(this._authService.getUserName()).subscribe((res: any) => {
      
      localStorage.removeItem(`iop_notification_redDotFlag_${this._authService.getUserName()}`);
      localStorage.removeItem(`iop_notification_${this._authService.getUserName()}`); // notificationList
      this._authService.loggedOut();
      sessionStorage.removeItem('siteTabNo');
      sessionStorage.removeItem('sensorTabNo');
      sessionStorage.removeItem('detectionFilter');
      sessionStorage.removeItem('privacyPolicy');
      sessionStorage.removeItem('profileTabNo');
      sessionStorage.removeItem('breachTabNo');
      localStorage.clear();
      sessionStorage.clear();
    }, error => {
      localStorage.removeItem(this._authService.getUserName());  // notificationList
      this._authService.loggedOut(); 
      throw new Error(error);
    })
  }

  activeTobarLink(item) {
    for (const key in this.MenuList) {
      if (this.MenuList[key]['name'] === item) {
        this.MenuList[key]['is_active'] = true;
      } else {
        this.MenuList[key]['is_active'] = false;
      }
      if (this.MenuList[key]['sub'] !== undefined) {
        // console.log("this.MenuList[key]['sub'] not equal to undefined");
      }
    }
    this.storage.set(MENULIST, this.MenuList);
    if (this.navbarCollapseElem) {
      this.navbarCollapseElem.classList.remove('show');
      this.navbarTogglerElem.classList.add('collapsed');
    }
    // this.navbarSubmenuCollapseElem.classList.remove('show');
  }

  mouseEnter(e) {

    this.navbarCollapseElem.classList.add('show');
    this.navbarSubmenuCollapseElem.classList.add('show');

    // this.navbarTogglerElem.classList.add('collapsed');
  }

  // mouseEnterParent() {
  //   this.dropdownToggle.classList.add('active');
  // }

  mouseLeave(e) {
    // console.log("leave> ",e.fromElement.innerHTML)
    this.navbarSubmenuCollapseElem.classList.remove('show');
    // this.navbarSubmenuCollapseElem = this.elRef.nativeElement.querySelector('.submenu');
  }

  disableTobarLink(name?:string) {
    if(name == 'profile') {
      sessionStorage.setItem('profileTabNo', '0');
    } else if(name == 'password') {
      sessionStorage.setItem('profileTabNo', '1');
    } else if (name == 'breach') {
      sessionStorage.setItem('breachTabNo', '0');
    }
    this._commonDataService._sendNotificationFlag(false);

    for (const key in this.MenuList) {
      this.MenuList[key]['is_active'] = false;
    }

    this.storage.set(MENULIST, this.MenuList);
    if (this.navbarCollapseElem) {
      this.navbarCollapseElem.classList.remove('show');
      this.navbarTogglerElem.classList.add('collapsed');
    }
  }

  // mouseHoverIn(item) {
  //   for (const key in this.MenuList) {
  //     if (this.MenuList[key]['name'] === item) {
  //       this.MenuList[key]['is_hover'] = true;
  //     } else {
  //       this.MenuList[key]['is_hover'] = false;
  //     }
  //   }
  // }

  // mouseHoverOut(item) {
  //   for (const key in this.MenuList) {
  //     if (this.MenuList[key]['name'] === item) {
  //       this.MenuList[key]['is_hover'] = false;
  //     } else {
  //       this.MenuList[key]['is_hover'] = false;
  //     }
  //   }
  // }

  // open notification list
  onNotification(event) {
    event.stopPropagation();
    if(this.notificationRedDotFlag) {
      this.toggleNotificationFlag = !this.toggleNotificationFlag;
      this._commonDataService._sendNotificationFlag(this.toggleNotificationFlag);
      if (this.navbarCollapseElem) {
        this.navbarCollapseElem.classList.remove('show');
        this.navbarTogglerElem.classList.add('collapsed');
      }
    } else {
      this._router.navigateByUrl('/home/notifications');
    }
  }

  // nav 
  onNotificationNav(getUrl) {
    this._router.navigate([getUrl]);
  }

  downloadFile(format) {
    let type = '';
    if (format === 'pdf') {
      type = 'application/pdf';
    } else if (format === 'xls') {
      type = 'application/vnd.ms-excel';
    } else {
      type = 'text/csv';
    }
    const dt: Date = new Date();
    const timeStamp = dt.getDate() + '-' + dt.getMonth() + '-' + dt.getFullYear() + '_' + dt.toLocaleTimeString();
    // console.log(timeStamp);
    const self = this;
    const xhr = new XMLHttpRequest();
    const url = apiData.url + 'sensors/filter/download';
    xhr.open('POST', url, true);
    xhr.responseType = 'blob';
    xhr.setRequestHeader('username', this._authService.getUserName());
    xhr.setRequestHeader('content-type', 'application/json');
    // Xhr callback when we get a result back
    // We are not using arrow function because we need the 'this' context
    xhr.onreadystatechange = function () {
      // If we get an HTTP status OK (200), save the file using fileSaver
      if (xhr.readyState === 4 && xhr.status === 200) {
        const blob = new Blob([this.response], { type: type });
        if (format === 'pdf') {
          saveAs(blob, 'PestActivityDayWiseReport' + timeStamp + '.pdf');
        } else if (format === 'xls') {
          saveAs(blob, 'PestActivityDayWiseReport' + timeStamp + '.xls');
        } else {
          saveAs(blob, 'PestActivityDayWiseReport' + timeStamp + '.csv');
        }
      }
    };

    // Start the Ajax request
    xhr.send('{"customerId":67}');
  }

  themeChange(num) {

    if (num === 4) {
      this.toggleThemeMood = 'light mood';
    } else {
      this.toggleThemeMood = 'dark mood';
    }

    // this.storage = this._authService.toggleStorage();
    // this.tempStorag = JSON.parse(this.storage.get(USER));
    this.tempStorag.theme = _.keys(theme)[num];
    this.storage.set(USER, JSON.stringify(this.tempStorag));
    this._themeService.changeTheme(_.keys(theme)[num]);

    let themeForm = new FormGroup({
      themeId: new FormControl(_.keys(theme)[num])
    })

    const data = new FormData();
    data.append('file', this.files);
    data.append('user', JSON.stringify(themeForm.value));

    this._profileService.updateData(data, this.userName).subscribe((res: any) => {

    })

  }

  ngOnDestroy() {
    if (this.subProfilePic) {
      this.subProfilePic.unsubscribe();
    }
  }
}
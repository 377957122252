import { theme } from './../../../classModel/themes';
import { AuthenticationService } from './../../../helpers/authentication.service';
import { MapService } from './../map/map.service';
import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { englishTranslate } from 'src/app/classModel/enTranslate';
import { japaneseTranslate } from 'src/app/classModel/jpTranslate';
import { spanishTranslate } from 'src/app/classModel/esTranslate';
import { ThemeService } from 'src/app/helpers/services/theme.service';
import { apiData } from 'src/app/common';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
const USER = 'user';

@Component({
  selector: 'app-sensor-line-chart',
  templateUrl: './sensor-line-chart.component.html',
  styleUrls: ['./sensor-line-chart.component.scss']
})

export class SensorLineChartComponent implements OnInit {

  storage: any;
  userName: string;
  chart: Chart;
  selectedFilterEndPoint: string;
  currentFilter: number;
  themeId: string;
  themeValue = { 'bgColor': 'red', 'textColor': 'red', 'gridLineColor': 'red', 'chartColor': '#0074AE', 'textSize': '10px', 'xAxisLabel': '', 'yAxisLabel': '' , 'cockroachColor' : '','cigaretteBeetleColor': '','flyColor': '', 'rodentColor': '', 'speciesColor': '','fruitFlyspeciesColor':'', 'houseFlyspeciesColor':'', 'hoverColor': '', 'girdLineColor' : '#EBEBEB'};
  chartFliterBtns = [{ name: 'hours', label: 'hours'},{ name: 'days', label: 'days'},{ name: 'weeks', label : 'weeks' }]; 
  chartDataResponse: any;
  getSpeciesType: number = 0;
  chartHeight = "440px";
  sysErrorImg: any;
  sysErrorImgEn = require('./../../../../assets/images/iconSet/system-error.svg');
  sysErrorImgJp = require('./../../../../assets/images/iconSet/system-error_jp.svg');
  sysErrorImgSp = require('./../../../../assets/images/iconSet/system-error_sp.svg');
  sysErrorImgFlag: boolean = false;

  @Input('getPayLoadObj') getPayLoadObj: any;
  @Input() randomNumber: any;
  @Input() filterSelection: any;
  unSubscribeFly: any;
  unSubscribeCockroach: any;
  unSubscribeRodent: any;
  notif_fly_count: webSocket.WebSocketRequest;
  trendline_Data: any;
  barchart_Data: any;
  toggleTitle: boolean = true;
  loadActivityTrentChart: boolean = true;
  infoFlag: boolean = false;
  replacedSensorFlag: boolean = false;
  allSiteFlag: boolean = false;
  flagToDisplay: boolean = false;
  allCustomerSiteFlag: boolean = false;
  lengendInfo: boolean = false;
  BoneCustomerFlag: boolean = false;
  private routerSubscription!: Subscription;

  constructor(private _router : Router, private _commonDataService: CommonDataService, private _themeService: ThemeService,private _mapService: MapService, private _authService: AuthenticationService) { 

    // retain old select while changing the tabs 
    let getFilterIndex = sessionStorage.getItem('pestDetectionFilterIndex');
    let filterIndexNumber  = Number(getFilterIndex);
    this.selectedFilterEndPoint = this.chartFliterBtns[filterIndexNumber]['name'];
    this.currentFilter = filterIndexNumber;

    console.log("filterIndexNumber ", filterIndexNumber)

    this.storage = this._authService.toggleStorage();
    this.flagToDisplay = JSON.parse(this.storage.get(USER)).role == 'SUPERADMIN' ? false : true;

    let languageId = JSON.parse(this.storage.get(USER)).languageId;
    let language = JSON.parse(this.storage.get(USER)).language;
    
    if (languageId == 1 || language == 'en-US') {

      this.sysErrorImg = this.sysErrorImgEn;

    } else if (languageId == 2 || language == 'ja-JP') {

      this.sysErrorImg = this.sysErrorImgJp;

    } else if (languageId == 3 || language == 'es-SP') {

      this.sysErrorImg = this.sysErrorImgSp;

    }
    
    this._themeService.themeChange.subscribe((theme) => {
      this.getTheme(this.chartDataResponse, theme);
    })

    this.unSubscribeFly = this._commonDataService.countFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) {
        this.onCheckCondition("count",message);
      }
    })

    this.unSubscribeCockroach = this._commonDataService.cockroachFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) {
        this.onCheckCondition("event",message);
      }
    })
    
    this.unSubscribeRodent = this._commonDataService.rodentFlag.subscribe((message: webSocket.WebSocketRequest) => {
      if (message.userid) {
        this.onCheckCondition("event",message);
      }
    })

    const getUrl = this._router.url;
    if(getUrl.includes("/detail/")) {
      this.toggleTitle = false;
    } else {
      this.toggleTitle = true;
    }

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.randomNumber || changes && changes.getPayLoadObj) {
      this.getPayLoadObj = this.getPayLoadObj ? this.getPayLoadObj : null;

      if(this.getPayLoadObj){

        if(this.getPayLoadObj.sensorTypeId) {
          this.getSpeciesType = this.getPayLoadObj.sensorTypeId;
          delete this.getPayLoadObj.sensorTypeId;
        }

        if(this.getPayLoadObj.replacedSensorIds) {
          this.replacedSensorFlag = true;
          delete this.getPayLoadObj.sensorTypeId;
        }

        if(!this.getPayLoadObj.customerId && !this.getPayLoadObj.siteId) {
          this.allCustomerSiteFlag = true;
        } else {
          this.allCustomerSiteFlag = false;
        }

        if(this.getPayLoadObj.customerId && !this.getPayLoadObj.siteId) {
          this.allSiteFlag = true;
        }else {
          this.allSiteFlag = false;
        }

        this.loadActivityTrentChart = false;

        this.callChartData();

      } 
    }

    // Subscribe to router events
    this.routerSubscription = this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.selectedFilterEndPoint = this.chartFliterBtns[1]['name'];
        this.currentFilter = 1;
        sessionStorage.setItem('pestDetectionFilterIndex', '1');
      }
    });

    
  }

  ngOnInit() {

    this.userName = this._authService.getUserName();
    this.loadActivityTrentChart = false;
    // this.selectedFilterEndPoint = this.chartFliterBtns[0]['name'];
    // this.currentFilter = 0
    
  }

  // poc graph data based on notification
  onCheckCondition(type,getData) {

    if(this.chartDataResponse) {
      if(!this.getPayLoadObj['customerId'] && !this.getPayLoadObj['siteId'] && !this.getPayLoadObj['sensorId'] && !this.getPayLoadObj['zoneId']) { // overview
        this.onsetGraphData(type,getData); 
      } else if (this.getPayLoadObj['zoneId'] && this.getPayLoadObj['zoneId'] == getData['zoneid'] && this.getPayLoadObj['sensorId'] == getData['sensorid']) { // zone Id are equal & sensor Id are equal
        this.onsetGraphData(type,getData);
      } else if(this.getPayLoadObj['siteId'] && this.getPayLoadObj['siteId'] == getData['siteid'] && this.getPayLoadObj['sensorId'] == getData['sensorid']) { // site Id are equal  & sensor Id are equal
        this.onsetGraphData(type,getData);
      }else if(this.getPayLoadObj['customerId'] && this.getPayLoadObj['customerId'] == getData['customerid'] && this.getPayLoadObj['sensorId'] == getData['sensorid']) { // customer Id are equal & sensor Id are equal
        this.onsetGraphData(type,getData);
      } else if ((this.getPayLoadObj['customerId'] == getData['customerid'] || this.getPayLoadObj['siteId'] == getData['siteid'] ||    this.getPayLoadObj['zoneId'] == getData['zoneid']) && !this.getPayLoadObj['sensorId']) { // has customerId, siteId, zoneId & not have sensor id
        this.onsetGraphData(type,getData);
      }
    }

  }

  onsetGraphData(type,getData) {

    const species = getData['species'];
    const cockroachCountArray = this.chartDataResponse['cockroachCount'];
    const flyCountArray = this.chartDataResponse['flyCount'];
    const rodentCountArray = this.chartDataResponse['rodentCount'];
    if(type == 'event') {

      switch (species) {
        case 'Rodent':
          const rodentIndex = rodentCountArray.length -2; // -2 last before
          let rodentValue = rodentCountArray[rodentIndex];
          rodentValue = rodentValue+1;
          rodentCountArray.splice(rodentIndex,1,rodentValue);
          this.chartDataResponse['rodentCount'] = rodentCountArray;
          break;
        case 'Cockroach':
          const cockroachIndex = cockroachCountArray.length -2; // -2 last before
          let cockroachValue = cockroachCountArray[cockroachIndex];
          cockroachValue = cockroachValue+1;
          cockroachCountArray.splice(cockroachIndex,1,cockroachValue);
          this.chartDataResponse['cockroachCount'] = cockroachCountArray;
          break;
        default:
          break; 
      }

    }else if(type == 'count') {

      switch (species) {
        case 'House-Fruit':
          const flyIndex = flyCountArray.length -2; // -2 last before
          let getNotific_flyValue = getData['flyCount'];
          let flyValue = flyCountArray[flyIndex];
          let set_flyValue = getNotific_flyValue + flyValue;
          flyCountArray.splice(flyIndex,1,set_flyValue);
          this.chartDataResponse['flyCount'] = flyCountArray;
          break;
        default:
          break; 
      }

    }
    this.getTheme(this.chartDataResponse);

  }

  // on click Filter
  onselectedFilterEndPoint(index) {
    this.loadActivityTrentChart = false;
    this.selectedFilterEndPoint = this.chartFliterBtns[index]['name'];
    this.currentFilter = index;
    sessionStorage.setItem('pestDetectionFilterIndex', index);
    this.callChartData();
  }

  // chart 
  async callChartData() {

    let payLoad = {}; //{ sensorType: this.selectedFilterEndPoint };

    if (this.getPayLoadObj && Object.keys(this.getPayLoadObj).length) {
      for (const key in this.getPayLoadObj) {
        if (this.getPayLoadObj[key]) {
          payLoad[key] = this.getPayLoadObj[key];

        }else if(key == 'customerId' || key == 'siteId') {
          payLoad[key] = null;
        }
      }
    }

   if(Object.keys(payLoad).length) {

    await this.callgraphAPI(payLoad);
  
   }else {

    console.log("Payload not available...!");

   }

  }

  async callgraphAPI(payLoad) {
  let APIarray = [];
    try {

     // bar graph api call
      const bar_url = apiData.url + apiData.home.activityTrendBarGraph + this.selectedFilterEndPoint;
      APIarray.push(this._mapService.getBarTrendChartData(payLoad, bar_url));

      // line graph api call
      const line_url = apiData.url + apiData.home.activityTrendLineGraph + this.selectedFilterEndPoint;
      this.getPayLoadObj.customerId && APIarray.push(this._mapService.getBarTrendChartData(payLoad, line_url));
      let responseArray = await this.executeForkJoin(APIarray);

      const data_for_bar = responseArray[0] ? responseArray[0]['pestTrendChartResponse'] : null;
      const data_for_line = responseArray[1] ? responseArray[1]['pestTrendChartResponse'] : null;
      this.sysErrorImgFlag = false;
      this.makeData(data_for_bar,data_for_line);
     
    }catch (error) {
     
      console.log("error >>", error);
      this.sysErrorImgFlag = true;
      this.loadActivityTrentChart = true;

    }
  }

  executeForkJoin(queryArray) {
    return new Promise( (resolve, reject) => {
      forkJoin(queryArray) 
      .subscribe( response => {
        resolve(response);
      }, error => {
        reject(error);
      });
    })
  }

  makeData(data_for_bar,data_for_line) {

    const convertToData = { };
    convertToData['category'] = data_for_bar['category'] ? (Object.keys(data_for_bar['category']).length ? data_for_bar['category'] : null) : null;

    if(data_for_bar){
      // bar
      convertToData['flyCount'] =  data_for_bar["flyCount"] ? (Object.keys(data_for_bar["flyCount"]).length ? data_for_bar["flyCount"] : null) : null;
      convertToData['fruitFlyCount'] =  data_for_bar["fruitFlyCount"] ? (Object.keys(data_for_bar["fruitFlyCount"]).length ? data_for_bar["fruitFlyCount"] : null) : null; 
      convertToData['houseFlyCount'] =  data_for_bar["houseFlyCount"] ? (Object.keys(data_for_bar["houseFlyCount"]).length ? data_for_bar["houseFlyCount"] : null) : null;
      convertToData['rodentCount'] =  data_for_bar["rodentCount"] ? (Object.keys(data_for_bar["rodentCount"]).length ? data_for_bar["rodentCount"] : null) : null;
      convertToData['cockroachCount'] = data_for_bar["cockroachCount"] ? (Object.keys(data_for_bar["cockroachCount"]).length ? data_for_bar["cockroachCount"] : null) : null;
      convertToData['cigaretteBeetleCount'] = data_for_bar["cigaretteBeetleCount"] ? (Object.keys(data_for_bar["cigaretteBeetleCount"]).length ? data_for_bar["cigaretteBeetleCount"] : null) : null;
      
      
    } else {
     
      convertToData['category'] = null;
      convertToData['flyCount'] = null;
      convertToData['fruitFlyCount'] = null;
      convertToData['houseFlyCount'] = null;
      convertToData['rodentCount'] = null;
      convertToData['cockroachCount'] = null;
      convertToData['cigaretteBeetleCount'] = null;

    }

    if(data_for_line){
      // line 
      convertToData['flyTrend'] = data_for_line["flyTrend"] ? (Object.keys(data_for_line["flyTrend"]).length ? data_for_line["flyTrend"] : null) : null;
      convertToData['fruitFlyTrend'] = data_for_line["fruitFlyTrend"] ? (Object.keys(data_for_line["fruitFlyTrend"]).length ? data_for_line["fruitFlyTrend"] : null) : null;
      convertToData['houseFlyTrend'] = data_for_line["houseFlyTrend"] ? (Object.keys(data_for_line["houseFlyTrend"]).length ? data_for_line["houseFlyTrend"] : null) : null;
      convertToData['rodentTrend'] = data_for_line["rodentTrend"] ? (Object.keys(data_for_line["rodentTrend"]).length ? data_for_line["rodentTrend"] : null) : null;
      convertToData['cockroachTrend'] = data_for_line["cockroachTrend"] ? (Object.keys(data_for_line["cockroachTrend"]).length ? data_for_line["cockroachTrend"] : null) : null;
      convertToData['cigaretteBeetleTrend'] = data_for_line["cigaretteBeetleTrend"] ? (Object.keys(data_for_line["cigaretteBeetleTrend"]).length ? data_for_line["cigaretteBeetleTrend"] : null) : null;
      
    }else {

      convertToData['flyTrend'] = null;
      convertToData['fruitFlyTrend'] = null;
      convertToData['houseFlyTrend'] = null;
      convertToData['rodentTrend'] = null;
      convertToData['cockroachTrend'] = null;
      convertToData['cigaretteBeetleTrend'] = null;

    }

    this.chartDataResponse = convertToData;

    // checking Highest Value in data
    if(convertToData['rodentCount'] && convertToData['cockroachCount'] && convertToData['cigaretteBeetleCount']) {
      let joinArray = convertToData['rodentCount'].concat(convertToData['cockroachCount'], convertToData['cigaretteBeetleCount']);

      let getHighestValue = Math.max.apply(0, joinArray);
      if(getHighestValue > 600 && getHighestValue < 800) {
        let graphHight = 580;
        this.chartHeight = `${graphHight}px`;
      } else if(getHighestValue > 900) {
        let graphHight = 480;
        this.chartHeight = `${graphHight}px`;
      }
    } else {
      let graphHight = 580;
      this.chartHeight = `${graphHight}px`;
    }
    
    this.getTheme(this.chartDataResponse);

  }

  getTheme(data?: any, themeId?: string) {
   
    this.storage = this._authService.toggleStorage();
    let seriesTitle = {};
    let noRecordsFound;

    let languageId = JSON.parse(this.storage.get(USER)).languageId;
    let language = JSON.parse(this.storage.get(USER)).language;
    let getTheme = JSON.parse(this.storage.get(USER)).theme;

     // set theme
     this.infoFlag = false;
     this.themeId = themeId ? themeId : getTheme; 
    
     this.themeValue.bgColor = theme[this.themeId].bgColor;
     this.themeValue.textColor = theme[this.themeId].textColor;
     this.themeValue.gridLineColor = theme[this.themeId].foreColor;
     this.themeValue.hoverColor = theme[this.themeId].hoverColor;
     this.themeValue.girdLineColor = theme[this.themeId].graphGridLineColor;
       
      if (languageId == 1 || language == 'en-US') {

        this.themeValue.yAxisLabel = englishTranslate['detections'];
        this.themeValue.xAxisLabel = englishTranslate[this.selectedFilterEndPoint];
        seriesTitle['Tobacco Beetle Detections'] = englishTranslate['Tobacco Beetle Detections'];
        seriesTitle['Crawling Insect Detections'] = englishTranslate['Crawling Insect Detections'];
        seriesTitle['Flying Insect Detections'] = englishTranslate['Flying Insect Detections'];
        seriesTitle['Rodent Detections'] = englishTranslate['Rodent Detections'];
        seriesTitle['Crawling Insect Trend'] = englishTranslate['Crawling Insect Trend'];
        seriesTitle['Tobacco Beetle Trend'] = englishTranslate['Tobacco Beetle Trend'];
        seriesTitle['Fly Trend'] = englishTranslate['Fly Trend'];
        seriesTitle['Rodent Trend'] = englishTranslate['Rodent Trend'];
        noRecordsFound = englishTranslate['no records found'];

      } else if (languageId == 2 || language == 'ja-JP') {

        this.themeValue.yAxisLabel = japaneseTranslate['detections'];
        this.themeValue.xAxisLabel = japaneseTranslate[this.selectedFilterEndPoint];
        seriesTitle['Tobacco Beetle Detections'] = japaneseTranslate['Tobacco Beetle Detections'];
        seriesTitle['Crawling Insect Detections'] = japaneseTranslate['Crawling Insect Detections'];
        seriesTitle['Flying Insect Detections'] = japaneseTranslate['Flying Insect Detections'];
        seriesTitle['Rodent Detections'] = japaneseTranslate['Rodent Detections'];
        seriesTitle['Crawling Insect Trend'] = japaneseTranslate['Crawling Insect Trend'];
        seriesTitle['Tobacco Beetle Trend'] = japaneseTranslate['Tobacco Beetle Trend'];
        seriesTitle['Fly Trend'] = japaneseTranslate['Fly Trend'];
        seriesTitle['Rodent Trend'] = japaneseTranslate['Rodent Trend'];
        noRecordsFound = japaneseTranslate['no records found'];

      } else if (languageId == 3 || language == 'es-SP') {  

        this.themeValue.yAxisLabel = spanishTranslate['detections'];
        this.themeValue.xAxisLabel = spanishTranslate[this.selectedFilterEndPoint];
        seriesTitle['Tobacco Beetle Detections'] = spanishTranslate['Tobacco Beetle Detections'];
        seriesTitle['Crawling Insect Detections'] = spanishTranslate['Crawling Insect Detections'];
        seriesTitle['Flying Insect Detections'] = spanishTranslate['Flying Insect Detections'];
        seriesTitle['Rodent Detections'] = spanishTranslate['Rodent Detections'];
        seriesTitle['Crawling Insect Trend'] = spanishTranslate['Crawling Insect Trend'];
        seriesTitle['Tobacco Beetle Trend'] = spanishTranslate['Tobacco Beetle Trend'];
        seriesTitle['Fly Trend'] = spanishTranslate['Fly Trend'];
        seriesTitle['Rodent Trend'] = spanishTranslate['Rodent Trend'];
        noRecordsFound = spanishTranslate['no records found'];

      }

      if(this.getSpeciesType) { // individual species
      
        switch (this.getSpeciesType) {
          case 1:
            this.themeValue.speciesColor = theme[this.themeId].rodentColor;  
            seriesTitle['count']  = 'rodentCount';
            seriesTitle['trend'] = 'rodentTrend';
            if (languageId == 1 || language == 'en-US') { 
              seriesTitle['barSpecies'] = englishTranslate['Rodent Detections'];
              seriesTitle['trendSpecies'] = englishTranslate['Rodent Trend'];
            } else if (languageId == 2 || language == 'ja-JP') {
              seriesTitle['barSpecies'] = japaneseTranslate['Rodent Detections'];
              seriesTitle['trendSpecies'] = japaneseTranslate['Rodent Trend'];
            } else if (languageId == 3 || language == 'es-SP') {
              seriesTitle['barSpecies'] = spanishTranslate['Rodent Detections'];
              seriesTitle['trendSpecies'] = spanishTranslate['Rodent Trend'];
            }

            let rodentDataArray = [];
            if(Math.max(...data['rodentCount']) != 0) {
              this.lengendInfo = true; 
              rodentDataArray.push({
                color: this.themeValue.speciesColor,
                type: 'column',
                name: seriesTitle['barSpecies'],
                data: data['rodentCount']
              });

            } else {

              let fakeArray = [];
              for(let i=0; i<data['category'].length; i++) {
                fakeArray.push(0);
              }
              rodentDataArray.push({
                color: '#fff',
                type: 'column',
                name: '',
                data: fakeArray
              });

            }
            
            if(this.selectedFilterEndPoint != 'weeks' && data['rodentTrend']) {  
              rodentDataArray.push({
                color: this.themeValue.speciesColor,
                type: 'spline',
                name: seriesTitle['trendSpecies'],
                data: data['rodentTrend'],
                marker: {
                  enabled: false
                }
              });
            }

            if(!data['rodentTrend']) {
              this.infoFlag = true;
            }

            this.chart =  this._mapService.pestTrendChart_specificSpecies(data, this.themeValue, rodentDataArray,noRecordsFound);
            break;  
          case 2:
            this.themeValue.fruitFlyspeciesColor = theme[this.themeId].flyColor;
            this.themeValue.houseFlyspeciesColor = theme[this.themeId].flyColor;
            if (languageId == 1 || language == 'en-US') { 
              seriesTitle['barSpecies'] = englishTranslate['Flying Insect Detections'];
              seriesTitle['trendSpecies'] = englishTranslate['Fly Trend'];
            } else if (languageId == 2 || language == 'ja-JP') {
              seriesTitle['barSpecies'] = japaneseTranslate['Flying Insect Detections'];
              seriesTitle['trendSpecies'] = japaneseTranslate['Fly Trend'];
            } else if (languageId == 3 || language == 'es-SP') {
              seriesTitle['barSpecies'] = spanishTranslate['Flying Insect Detections'];
              seriesTitle['trendSpecies'] = spanishTranslate['Fly Trend'];
            }

            let flyDataArray = [];
            if(Math.max(...data['flyCount']) != 0) {
              this.lengendInfo = true; 
              flyDataArray.push({
                color: this.themeValue.fruitFlyspeciesColor,
                type: 'column',
                name: seriesTitle['barSpecies'],
                data: data['flyCount']
              });

            }else {

              let fakeArray = [];
              for(let i=0; i<data['category'].length; i++) {
                fakeArray.push(0);
              }
              flyDataArray.push({
                color: '#fff',
                type: 'column',
                name: '',
                data: fakeArray
              });

            }
            
            if(this.selectedFilterEndPoint != 'weeks') { 
              if(data['flyTrend']) {
                flyDataArray.push({
                  color: this.themeValue.fruitFlyspeciesColor,
                  type: 'spline',
                  name: seriesTitle['trendSpecies'],
                  data: data['flyTrend'],
                  marker: {
                    enabled: false
                  }
                })
              }
            }

            if(!data['flyTrend']) {
              this.infoFlag = true;
            }

            this.chart = this._mapService.pestTrendChart_flySpecies(data, this.themeValue, flyDataArray,noRecordsFound);

            break;
          case 3:
            
            this.themeValue.speciesColor = theme[this.themeId].cockroachColor;
            if (languageId == 1 || language == 'en-US') { 
              seriesTitle['barSpecies'] = englishTranslate['Crawling Insect Detections'];
              seriesTitle['trendSpecies'] = englishTranslate['Crawling Insect Trend'];
            } else if (languageId == 2 || language == 'ja-JP') {
              seriesTitle['barSpecies'] = japaneseTranslate['Crawling Insect Detections'];
              seriesTitle['trendSpecies'] = japaneseTranslate['Crawling Insect Trend'];
            } else if (languageId == 3 || language == 'es-SP') { 
              seriesTitle['barSpecies'] = spanishTranslate['Crawling Insect Detections'];
              seriesTitle['trendSpecies'] = spanishTranslate['Crawling Insect Trend'];
            }

            let cockroachDataArray = [];
            if(Math.max(...data['cockroachCount']) != 0) {
              this.lengendInfo = true; 
              cockroachDataArray.push({
                color: this.themeValue.speciesColor,
                type: 'column',
                name: seriesTitle['barSpecies'],
                data: data['cockroachCount']
              });

            } else {

              let fakeArray = [];
              for(let i=0; i<data['category'].length; i++) {
                fakeArray.push(0);
              }
              cockroachDataArray.push({
                color: '#fff',
                type: 'column',
                name: '',
                data: fakeArray
              });

            }

            if(this.selectedFilterEndPoint != 'weeks' && data['cockroachTrend']) { 
              cockroachDataArray.push({
                color: this.themeValue.speciesColor,
                type: 'spline',
                name: seriesTitle['trendSpecies'],
                data: data['cockroachTrend'],
                marker: {
                  enabled: false
                }
              });
            }

            if(!data['cockroachTrend']) {
              this.infoFlag = true;
            }

            this.chart = this._mapService.pestTrendChart_specificSpecies(data, this.themeValue, cockroachDataArray,noRecordsFound);
          break;

          case 5:
            this.themeValue.speciesColor = theme[this.themeId].cigaretteBeetleColor;
            if (languageId == 1 || language == 'en-US') { 
              seriesTitle['barSpecies'] = englishTranslate['Tobacco Beetle Detections'];
              seriesTitle['trendSpecies'] = englishTranslate['Tobacco Beetle Trend'];
            } else if (languageId == 2 || language == 'ja-JP') {
              seriesTitle['barSpecies'] = japaneseTranslate['Tobacco Beetle Detections'];
              seriesTitle['trendSpecies'] = japaneseTranslate['Tobacco Beetle Trend'];
            } else if (languageId == 3 || language == 'es-SP') { 
              seriesTitle['barSpecies'] = spanishTranslate['Tobacco Beetle Detections'];
              seriesTitle['trendSpecies'] = spanishTranslate['Tobacco Beetle Trend'];
            }

            let cigaretteBeetleDataArray = [];
            if(Math.max(...data['cigaretteBeetleCount']) != 0) {
              this.lengendInfo = true; 
              cigaretteBeetleDataArray.push({
                color: this.themeValue.speciesColor,
                type: 'column',
                name: seriesTitle['barSpecies'],
                data: data['cigaretteBeetleCount']
              });

            } else {

              let fakeArray = [];
              for(let i=0; i<data['category'].length; i++) {
                fakeArray.push(0);
              }
              cigaretteBeetleDataArray.push({
                color: '#fff',
                type: 'column',
                name: '',
                data: fakeArray
              });

            }

            if(this.selectedFilterEndPoint != 'weeks' && data['cigaretteBeetleTrend']) { 
              cigaretteBeetleDataArray.push({
                color: this.themeValue.speciesColor,
                type: 'spline',
                name: seriesTitle['trendSpecies'],
                data: data['cigaretteBeetleTrend'],
                marker: {
                  enabled: false
                }
              });
            }

            if(!data['cigaretteBeetleTrend']) {
              this.infoFlag = true;
            }

            this.chart = this._mapService.pestTrendChart_specificSpecies(data, this.themeValue, cigaretteBeetleDataArray,noRecordsFound);
            break;
        }

      }else {

        this.themeValue.cockroachColor = theme[this.themeId].cockroachColor;
        this.themeValue.flyColor = theme[this.themeId].flyColor;
        this.themeValue.rodentColor = theme[this.themeId].rodentColor;
        this.themeValue.cigaretteBeetleColor = theme[this.themeId].cigaretteBeetleColor;
        let seriesDataArray = [];

        // bar chart all array are zero value
        if((Math.max(...data['cigaretteBeetleCount']) == 0 && Math.max(...data['cockroachCount']) == 0 && Math.max(...data['flyCount']) == 0 && Math.max(...data['rodentCount']) == 0)) {
          
          this.lengendInfo = false;
          let fakeArray = [];
          for(let i=0; i<data['category'].length; i++) {
            fakeArray.push(0);
          }
          seriesDataArray.push({
            color: '#fff',
            type: 'column',
            name: '',
            data: fakeArray
          })

        } else { // bar chart might some array has value
          
          this.lengendInfo = true; 
          if(Math.max(...data['cigaretteBeetleCount']) != 0) {
            seriesDataArray.push({
              color: this.themeValue.cigaretteBeetleColor,
              type: 'column',
              name: seriesTitle['Tobacco Beetle Detections'],
              data: data['cigaretteBeetleCount']//[1, 4, 3]
            })
          }
          if(Math.max(...data['cockroachCount']) != 0) {
            seriesDataArray.push({
              color: this.themeValue.cockroachColor,
              type: 'column',
              name: seriesTitle['Crawling Insect Detections'],
              data: data['cockroachCount']//[1, 4, 3]
            })
          }
          if(Math.max(...data['flyCount']) != 0) {
            seriesDataArray.push({
              color: this.themeValue.flyColor,
              type: 'column',
              name: seriesTitle['Flying Insect Detections'],
              data: data['flyCount']
            })
          }
          if(Math.max(...data['rodentCount']) != 0) {
            seriesDataArray.push({
              color: this.themeValue.rodentColor,
              type: 'column',
              name: seriesTitle['Rodent Detections'],
              data: data['rodentCount']
            })
          }
        }
       
        // line chat
        if(this.selectedFilterEndPoint != 'weeks') {
          if(data['cigaretteBeetleTrend']) {
            seriesDataArray.push({
              color: this.themeValue.cigaretteBeetleColor,
              type: 'spline',
              name: seriesTitle['Tobacco Beetle Trend'],
              data: data['cigaretteBeetleTrend'],
              marker: {
                enabled: false
              }
            });
          }
          if(data['cockroachTrend']) {
            seriesDataArray.push({
              color: this.themeValue.cockroachColor,
              type: 'spline',
              name: seriesTitle['Crawling Insect Trend'],
              data: data['cockroachTrend'],
              marker: {
                enabled: false
              }
            });
          }
          if(data['flyTrend']) {
            seriesDataArray.push({
              color: this.themeValue.flyColor,
              type: 'spline',
              name: seriesTitle['Fly Trend'],
              data: data['flyTrend'],
              marker: {
                enabled: false
              }
            });
          }
          if(data['rodentTrend']) {
            seriesDataArray.push({
              color: this.themeValue.rodentColor,
              type: 'spline',
              name: seriesTitle['Rodent Trend'],
              data: data['rodentTrend'],
              marker: {
                enabled: false
              }
            });
          }
        }
        if((!data['cockroachTrend'] && !data['flyTrend'] && !data['rodentTrend'] && !data['cigaretteBeetleTrend']) || this.currentFilter == 2) {
          this.BoneCustomerFlag = this.getPayLoadObj.customerId ? false : true;
          this.infoFlag = true;
        }
        this.chart = this._mapService.pestTrendChart_allSpecies(data, this.themeValue, seriesDataArray,noRecordsFound);
        
      }

      this.loadActivityTrentChart = true;
  }

  ngOnDestroy() {
    if (this.unSubscribeFly) {
      this.unSubscribeFly.unsubscribe();
    }
    if (this.unSubscribeCockroach) {
      this.unSubscribeCockroach.unsubscribe();
    }
    if (this.unSubscribeRodent) {
      this.unSubscribeRodent.unsubscribe();
    }
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  } 

}
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { Status } from './../../../helpers/config/status';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthenticationService } from 'src/app/helpers/authentication.service';
import { SensorService } from 'src/app/modules/iop-module/sensors/sensor.service';
import { CommonDataService } from 'src/app/helpers/services/common-data.service';
import { MessageBoxService, MessageDefinition } from '../message-box/message-box.service';
import { englishTranslate } from 'src/app/classModel/enTranslate';
import { japaneseTranslate } from 'src/app/classModel/jpTranslate';
import { spanishTranslate } from 'src/app/classModel/esTranslate';
import { Location } from '@angular/common';
import { apiData } from 'src/app/common';

const USER = 'user';

@Component({
  selector: 'app-edit-sensor',
  templateUrl: './edit-sensor.component.html',
  styleUrls: ['./edit-sensor.component.scss']
})
export class EditSensorComponent implements OnInit {

  @Input() getSensorDetails: any;
  @Input() reloadScreenFlag: any;
  @Output() setPopupClose = new EventEmitter<boolean>();
  @Output() setReloadScreen = new EventEmitter<boolean>();

  editSensor: FormGroup;
  options: any;
  resTrapTypeList = [{name:"Glue Trap",key:"Glue Trap"},{name:"Standalone",key:"Standalone"},{name:"Bait Station",key:"Bait Station"}];
  trapTypeList = [];
  sensorTypes: sensorType.Content[] = [];
  files: File;
  flag: boolean = false;
  sensorRowId: any;
  // sensorImage: any;
  displaySensorImageList: any;
  sensorImageUrlList: any;
  sensorNameErrorMsg: string;
  storage: any;

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

  constructor(private formBuilder: FormBuilder, private _authService: AuthenticationService, private _sensorService: SensorService,private _messageBoxService: MessageBoxService, private _commonDataService : CommonDataService, private _location: Location) { }

  ngOnInit() {

    // Form Validation
    if (this.getSensorDetails.sensorTypeId.sensorTypeId == 2 || this.getSensorDetails.sensorTypeId.sensorTypeId == 5) { // fly / Cigarette Beetle sensor
      this.editSensor = this.formBuilder.group({
        sensorId: new FormControl('', [Validators.required,Validators.minLength(6),Validators.maxLength(16),Validators.pattern('^[a-zA-Z0-9]{6,}$')]),
        trapType: new FormControl(''),
        sensorName: new FormControl('', [Validators.required,,Validators.minLength(2),Validators.maxLength(25),Validators.pattern('^(?! )[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤 _:-]*(?<! )$')]), //  '/[一-龠]+|[ぁ-ゔ]+|[ァ-ヴー]+|[a-zA-Z0-9]+|[ａ-ｚＡ-Ｚ０-９]+|[々〆〤]+/u'
        sensorTypeId: new FormControl('', [Validators.required]),
        glueboardExpiryInDays : [1, [Validators.required,Validators.min(1),Validators.max(90)]],
        maxFlyCount : [1, [Validators.required,Validators.min(1),Validators.max(1999)]],
        description: new FormControl('', [Validators.required,Validators.minLength(2),Validators.maxLength(100)]),
        pestTypeName : new FormControl('', [Validators.required])
      });

      if(this.getSensorDetails.sensorTypeId.sensorTypeId == 2 || this.getSensorDetails.sensorTypeId.sensorTypeId == 5) {

        this.editSensor.get('glueboardExpiryInDays').setValue(this.getSensorDetails.flySensorBreachLimit.expiryInDays);
        this.editSensor.get('maxFlyCount').setValue(this.getSensorDetails.flySensorBreachLimit.maxFlyCount);

      } 

    } else {
      this.editSensor = this.formBuilder.group({
        sensorId: new FormControl('', [Validators.required,Validators.minLength(6),Validators.maxLength(16),Validators.pattern('^[a-zA-Z0-9]{6,}$')]),
        trapType: new FormControl(''),
        sensorName: new FormControl('', [Validators.required,Validators.minLength(2), Validators.maxLength(25),Validators.pattern('^(?! )[一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤 _:-]*(?<! )$')]),
        sensorTypeId: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required,Validators.minLength(2),Validators.maxLength(100)]),
        pestTypeName : new FormControl('', [Validators.required])
      });
    }

    this.getSensorTypes();
    
    if(this.getSensorDetails.sensorImageUrlList && this.getSensorDetails.sensorImageUrlList.length > 0) {
      
      this.displaySensorImageList = JSON.parse(JSON.stringify(this.getSensorDetails.sensorImageUrlList));
      this.sensorImageUrlList = JSON.parse(JSON.stringify(this.getSensorDetails.sensorImageUrlList));

    } else {

      this.displaySensorImageList = [];
      this.sensorImageUrlList = [];

    }

    this.editSensor.get('sensorId').setValue(this.getSensorDetails.sensorId);
    this.editSensor.get('sensorTypeId').setValue(this.getSensorDetails.sensorTypeId.sensorTypeId);
    this.editSensor.get('description').setValue(this.getSensorDetails.description);
    this.editSensor.get('sensorName').setValue(this.getSensorDetails.sensorName);
    this.editSensor.get('trapType').setValue(this.getSensorDetails.trapType);

    this.storage = this._authService.toggleStorage();
    let languageId = JSON.parse(this.storage.get(USER)).languageId;
    let language = JSON.parse(this.storage.get(USER)).language;

    let setPestTypeName;
    let getPestTypeName = this.getSensorDetails.sensorTypeId.displayName;
    getPestTypeName = getPestTypeName.toLowerCase();

    if (languageId == 1 || language == 'en-US') { 
      setPestTypeName = englishTranslate[getPestTypeName];
    } else if (languageId == 2 || language == 'ja-JP') {
      setPestTypeName = japaneseTranslate[getPestTypeName];
    } else if (languageId == 3 || language == 'es-SP') { 
      setPestTypeName = spanishTranslate[getPestTypeName];
    }

    this.editSensor.get('pestTypeName').setValue(setPestTypeName);
    this.editSensor.controls['sensorId'].disable();

    this.editSensor.controls['pestTypeName'].disable();
    this.sensorRowId = this.getSensorDetails.id;
    const sensorTypeId = this.getSensorDetails.sensorTypeId.sensorTypeId;
    this.editSensor.get('trapType').setValue(this.getSensorDetails.trapType);
    this.editSensor.get('trapType').setValue(this.getSensorDetails.trapType);
    this.trapTypeDropdownList(sensorTypeId);
    // console.log("this.getSensorDetails >> ", this.getSensorDetails)

  }

  // sensorName paste
  pasteSensorNameEvent(event: ClipboardEvent) {

    let clipboardData = event.clipboardData || window['clipboardData'];
    let pastedText = clipboardData.getData('text');
    this.getSensorNameDetails(pastedText);
  
  }
  
  // sensor name
  getSensorNameDetails(text) {

    let sensorName = text.trim();
    if (text.length > 1 && text.length < 26 && sensorName != this.getSensorDetails['sensorName']) { 
      this.callSensorNameUnique(sensorName);
    }
    
  }
  
  // check sensor name unique
  callSensorNameUnique(resultString?: any) { 

    const sensor_name = resultString.trim();
    const payLoad = {};
    payLoad['customerId'] = this.getSensorDetails['customerId']['customerId'];
    payLoad['siteId'] = this.getSensorDetails['siteId']['siteId'];
    payLoad['sensorName'] = sensor_name;

    const url = apiData.url + apiData.sensors.sensorNameIsExits;

    this._sensorService.getSensorNameIsExist(payLoad, url).subscribe((sensorNameResp: sensorName.response) => {
      if (sensorNameResp.status === Status.OK) {

        this.editSensor.get('sensorName').setValue(sensor_name);
        // this.secondFormGroup.get('sensorName').setErrors({ sensorNameCorrect: false });
        this.editSensor.get('sensorName').setErrors({ sensorNameWrong: false });
        this.editSensor.get('sensorName').setErrors(null);

      } else { // status error

        this.sensorNameErrorMsg = sensorNameResp.message;
        this.editSensor.get('sensorName').setValue(sensor_name);
        this.editSensor.get('sensorName').setErrors({ sensorNameWrong: true });

      }
    });
  }

  // deployment type option list
  trapTypeDropdownList(sensorTypeId){
    if(sensorTypeId == null){

      this.trapTypeList = this.resTrapTypeList;

    }else if(sensorTypeId == 2 || sensorTypeId == 5){ // Fly & Cigarette Beetle Sensor

      this.trapTypeList = this.resTrapTypeList.filter((item) =>  item.key == 'Glue Trap');
      this.editSensor.get('trapType').setValue('Glue Trap');

    } else if (sensorTypeId == 3) { // crawling insect

      this.trapTypeList = this.resTrapTypeList.filter((item) => item.key != 'Bait Station');

    } else if (sensorTypeId == 1) { // rodent sensor

      this.trapTypeList = this.resTrapTypeList.filter((item) => item.key != 'Glue Trap');

    }
  }

  // sensor type list
  getSensorTypes() {
    this._sensorService.getSensorTypes().subscribe((sensorTypes: sensorType.sensorTypeResponse) => {
      if (sensorTypes.status === Status.OK) {
        this.sensorTypes = sensorTypes.data.content;
      }
    })
  }

  // img upload
  triggerFile() {
    this.fileInput.nativeElement.click();
  }

  getFiles(event) {
    const self = this;
    let temp: any;
    const type = event.target.files[0].type;
    this.files = event.target.files[0];
    if (this.files.type.indexOf('image') >= 0) {

      let FileSize = this.files.size / 1024 / 1024; 

      if(FileSize > 5) { // not allow more than 5 MB

        this.files = null;
        this._messageBoxService.openMessageBox('alert', 'file size should be less than 5 MB');

      }else { // allow less then 5 MB

        const fileName = this.files['name'];
        let splitArray = fileName.split('.');
        splitArray = splitArray.map((x) => { return x.toLowerCase(); });

        if(splitArray.indexOf('png') > -1 || splitArray.indexOf('jpg') > -1 || splitArray.indexOf('jpeg') > -1 || splitArray.indexOf('PNG') > -1 || splitArray.indexOf('JPG') > -1 || splitArray.indexOf('JPEG') > -1) {

          const myReader: FileReader = new FileReader();
          myReader.readAsDataURL(this.files);
          let resultSet = '';
          myReader.onloadend = (e) => {
            const columns = (<string>myReader.result).split(/\r\n|\r|\n/g);
            for (let i = 0; i < columns.length; i++) {
              resultSet = resultSet.concat(columns[i].split(' ')[0]);
              // this.sensorImage = resultSet;
              const obj = { imgUrl : resultSet, file : this.files };
              this.sensorImageUrlList.push(obj);
              this.displaySensorImageList.push(resultSet);

            }
          }
          
        } else {

          this.files = null;
          this._messageBoxService.openMessageBox('alert', 'file formats should be JPG/JPEG/PNG');

        }

      }
      
    } else {
      this.files = null;
      this._messageBoxService.openMessageBox('alert', 'please choose a image file');
    }

  }

  // remove selected sensor img
  onRemoveSensorImg(imgIndex) {
    this.displaySensorImageList.splice(imgIndex, 1);
    this.sensorImageUrlList.splice(imgIndex, 1);
  }

  onSubmit(value) {

    let formPayload = value;
    formPayload['sensorId'] = this.getSensorDetails.sensorId;
    formPayload['sensorTypeId'] = this.getSensorDetails.sensorTypeId.sensorTypeId;
    formPayload['zoneId'] = this.getSensorDetails.zoneId.zoneId;
    const data = new FormData();

    const array = [];
    for(let i = 0 ; i <Object.keys(this.sensorImageUrlList).length; i++) {

      if(this.sensorImageUrlList[i]['file']){
        const getImgFile = this.sensorImageUrlList[i]['file'];
        data.append('file', getImgFile);
      }else if(this.displaySensorImageList[i]) {
        array.push(this.displaySensorImageList[i]);
      }

    }

    formPayload['sensorImgStrUrls'] = array;

    const sensor_name = value.sensorName;
    value.sensorName = sensor_name.trim();
    data.append('sensor', JSON.stringify(formPayload));

    if(this.getSensorDetails.sensorName.toLowerCase() != sensor_name.toLowerCase()) { // user modified the sensor name
      
      const payLoad = {};
      payLoad['customerId'] = this.getSensorDetails['customerId']['customerId'];
      payLoad['siteId'] = this.getSensorDetails['siteId']['siteId'];
      payLoad['sensorId'] = this.getSensorDetails.sensorId;
      payLoad['sensorName'] = sensor_name;
    
      const url = apiData.url + apiData.sensors.sensorNameIsExits;
      // Sensor name is exist
      this._sensorService.getSensorNameIsExist(payLoad, url).subscribe((sensorNameResp: sensorName.response) => {
        if (sensorNameResp.status === Status.OK) {
    
          this.editSensor.get('sensorName').setValue(sensor_name);
          this.editSensor.get('sensorName').setErrors({ sensorNameWrong: false });
          this.editSensor.get('sensorName').setErrors(null);

          // update sensor API
          this._sensorService.updateSensor(data, this.sensorRowId).subscribe((res: any) => {
            if (res.status === Status.OK) {
      
              this._messageBoxService.openMessageBox('alert', this.flag ? MessageDefinition.REASSIGN : MessageDefinition.SENSOREDIT)
                .subscribe(() => {
                  if(!this.reloadScreenFlag) {
                    this.setReloadScreen.emit(false);
                    this._location.back();
                  }else {
                    this.setReloadScreen.emit(true);
                  }
                });
      
            }else {
              this._messageBoxService.openMessageBox('alert', res.message);
            }
          });
    
        } else { // status error
    
          this.sensorNameErrorMsg = sensorNameResp.message;
          this.editSensor.get('sensorName').setValue(sensor_name);
          this.editSensor.get('sensorName').setErrors({ sensorNameWrong: true });
    
        }
      });
    } else {

      // update sensor API
      this._sensorService.updateSensor(data, this.sensorRowId).subscribe((res: any) => {
        if (res.status === Status.OK) {
  
          this._messageBoxService.openMessageBox('alert', this.flag ? MessageDefinition.REASSIGN : MessageDefinition.SENSOREDIT)
            .subscribe(() => {
              if(!this.reloadScreenFlag) {
                this.setReloadScreen.emit(false);
                this._location.back();
              }else {
                this.setReloadScreen.emit(true);
              }
            });
  
        }else {
          this._messageBoxService.openMessageBox('alert', res.message);
        }
      });

    }
  }

  // Cancel Popup
  onCancelPopup() {
    this.setPopupClose.emit(false);
  }
}